import { Button } from "@material-ui/core";
import { ArrowRight, ErrorOutline } from "@material-ui/icons";

import HashLink from "../../shared-components/custom-hash-link/hash-link";
import { covid } from "../../../routes/paths";

import "../../../../css/banners/banners.scss";

export default function COVIDBanner() {
  return (
    <div className={"covid-banner"}>
      <Button
        variant={"text"}
        component={HashLink}
        to={covid}
        startIcon={<ErrorOutline />}
        endIcon={<ArrowRight />}
      >
        <h2>COVID-19 (CORONAVIRUS) - Testing and Vaccination Information</h2>
      </Button>
    </div>
  );
}
