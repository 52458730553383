import { Fragment } from "react";

import blanchardMaternity from "../../../../assets/imgs/portraits/blanchard-maternity.jpg";
import maternityImg1 from "../../../../assets/imgs/portraits/freestocks-pregnancy.jpg";
import maternityImg2 from "../../../../assets/imgs/portraits/christian-bowen-new-born.jpg";
import birthingroom from "../../../../assets/imgs/backgrounds/birthing-room.jpg"

const links = [
  {
    src: maternityImg1,
    linkStyles: {
      backgroundPosition: "-30px 50px",
    },
    title: "Childbirth Preparation Classes",
    content: {
      content: (
        <Fragment>
          <p>
            Our classes will prepare you for labor and delivery, and the first
            year with your baby.
          </p>
        </Fragment>
      ),
      extLink: `${process.env.PUBLIC_URL}/downloads/FMC-childbirth-classes.pdf`,
    },
  },
  {
    src: maternityImg2,
    linkStyles: {
      backgroundPosition: "-30px 32px",
    },
    title: "High-Risk Pregnancy Care",
    content: {
      content: (
        <Fragment>
          <p>
            Our physicians can diagnose, treat and help you manage pregnancy
            complications.
          </p>
        </Fragment>
      ),
      items: [
        "Genetic Counseling",
        "Prenatal Testing",
        "Diabetes Management during Pregnancy",
      ],
    },
  },
  {
    src: birthingroom,
    linkStyles: {
      backgroundPosition: "0 20px",
    },
    title: "Single-room Birthing",
    content: {
      content: (
        <Fragment>
          <p>
            We are committed to providing the highest level of comfort and care
            during your delivery experience. We utilize Weiser Memorial
            Hospital's modern and spacious birthing suites. They are beautifully
            designed to help expectant moms feel at home.
          </p>
        </Fragment>
      ),
    },
  },
  {
    src: blanchardMaternity,
    linkStyles: {
      backgroundPosition: "0 40px",
    },
    title: "Caring Providers",
    content: {
      content: (
        <Fragment>
          <p>
            We have a provider who fits your needs, whether you're looking for a
            low intervention birth or need care for a high-risk pregnancy.
          </p>
        </Fragment>
      ),
    },
  },
];

export default links;
