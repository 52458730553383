import {
  setBirthClassList,
  setDownloadsList,
  setERList,
  setPractitionersList,
  setProviderData,
  setTreatmentList,
  toggleMenu,
} from "../actions/actions";

const initState = {
  url: "https://github.com/wmhospital/",
  menu: {
    open: false,
  },
  covidPopup: true,
  isIE: false,
  vaccineAvailable: true,
  currentBirthStory: {},

  // Popup state
  showTreatmentList: false,
  showDownloadsList: false,
  showERList: false,
  showBirthClassesList: false,
  showPractitionersList: false,

  providerDataOpen: false,

  isDev: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
};

function rootReducer(state = initState, action) {
  switch (action.type) {
    case toggleMenu:
      return Object.assign({}, state, {
        menu: {
          ...state.menu,
          open: action.payload,
        },
      });
    case "SET_isIE":
      return Object.assign({}, state, {
        isIE: action.payload,
      });
    case "SET_VACCINE_AVAILABLE":
      return Object.assign({}, state, {
        vaccineAvailable: action.payload,
      });
    case "SET_CURRENT_BIRTH_STORY":
      return Object.assign({}, state, {
        currentBirthStory: action.payload,
      });
    case setTreatmentList:
      return Object.assign({}, state, {
        showTreatmentList: action.payload,
      });
    case setDownloadsList:
      return Object.assign({}, state, {
        showDownloadsList: action.payload,
      });
    case setERList:
      return Object.assign({}, state, {
        showERList: action.payload,
      });
    case setBirthClassList:
      return Object.assign({}, state, {
        showBirthClassList: action.payload,
      });
    case setPractitionersList:
      return Object.assign({}, state, {
        showPractitionersList: action.payload,
      });

    case setProviderData:
      return Object.assign({}, state, {
        providerDataOpen: action.payload,
      });

    default:
      return state;
  }
}

export default rootReducer;
