import { Button, Icon, List, ListItem, Modal, Paper } from "@material-ui/core";
import { Description, OpenInNew } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import ListOfTreatments from "../shared-components/list-of-treatments/list-of-treatments";
import VisitTheER from "../shared-components/visit-the-er/visit-the-er";
import { CloseButton } from "../buttons/buttons";
import { forms } from "../popups/data/forms";

import BirthClasses from "./data/birth-classes";
import C2G from "../c2g/c2g";
import { store } from "../../redux/store";
import {
  setPractitionersList,
  setTreatmentList,
  setERList,
  setBirthClassList,
  setDownloadsList,
} from "../../redux/actions/actions";

import "../../../css/popups/popup.scss";

export const setModal = (modal, state) => {
  let dispatch = store.dispatch;
  switch (modal) {
    case "treatment":
      modal = setTreatmentList;
      break;
    case "practitioners":
      modal = setPractitionersList;
      break;
    case "er":
      modal = setERList;
      break;
    case "birthing":
      modal = setBirthClassList;
      break;
    case "downloads":
      modal = setDownloadsList;
      break;
    default:
      modal = "";
  }

  dispatch({ type: modal, payload: state });
};

export function Popup({
  open,
  onClose,
  className,
  children,
  buttonStyles,
  ...rest
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={`modal-root ${className && className}`}
      {...rest}
    >
      <div>
        <CloseButton onClose={onClose} style={buttonStyles} />
        {children}
      </div>
    </Modal>
  );
}

export function TreatmentPopup() {
  const showModalStoreVal = useSelector((state) => state.showTreatmentList);

  return (
    <Popup
      open={showModalStoreVal}
      onClose={() => setModal("treatment", false)}
      buttonStyles={{ color: "white" }}
    >
      <div className={"treatment-popup-root"}>
        <ListOfTreatments style={{ color: "white" }} />
      </div>
    </Popup>
  );
}

export function PractitionersPopup() {
  const showModalStoreVal = useSelector((state) => state.showPractitionersList);

  return (
    <Popup
      open={showModalStoreVal}
      onClose={() => setModal("practitioners", false)}
      buttonStyles={{ color: "white" }}
    >
      <div className={"practitioners-popup-root"}>
        <C2G />
      </div>
    </Popup>
  );
}

export function VisitERPopup() {
  const showModalStoreVal = useSelector((state) => state.showERList);

  return (
    <Popup open={showModalStoreVal} onClose={() => setModal("er", false)}>
      <VisitTheER />
    </Popup>
  );
}

export function BirthingClassesPopup() {
  const showModalStoreVal = useSelector((state) => state.showBirthClassList);
  return (
    <Popup open={showModalStoreVal} onClose={() => setModal("birth", false)}>
      <BirthClasses />
    </Popup>
  );
}

export function DownloadsPopup(props) {
  const modalOpen = useSelector((state) => state.showDownloadsList);

  return (
    <Popup
      open={modalOpen}
      onClose={() => setModal("downloads", false)}
      className={"downloads"}
    >
      <Paper className={"file-list-root"}>
        <h2 className={"primary-title"}>
          Patient Forms{" "}
          <Icon>
            <Description />
          </Icon>
        </h2>
        <p>
          We are working on making all the necessary patient forms available on
          this website. All the documents will be in PDF (Adobe Acrobat) format.
        </p>
        <List>
          {Array.isArray(forms) &&
            forms.map((f, i) => (
              <ListItem key={i}>
                <Button
                  variant={"text"}
                  component={"a"}
                  href={f.link && f.link}
                  color={"primary"}
                  startIcon={<OpenInNew />}
                >
                  {f.title}
                </Button>
              </ListItem>
            ))}
        </List>
      </Paper>
    </Popup>
  );
}

export default function ModalBase() {
  const modelList = [
    <TreatmentPopup key={0.1} />,
    <VisitERPopup key={0.2} />,
    <DownloadsPopup key={0.3} />,
    <PractitionersPopup key={0.4} />,
  ];

  return modelList;
}
