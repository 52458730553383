import ContactCard from './contact-card';

export default function FMCContactCard(props) {
    return (
        <ContactCard 
            label={"FAMILY MEDICAL CENTER"}
            href={"360 East Liberty Weiser, ID 83672"}
            address={<><p>360 East Liberty</p> <p>Weiser, ID 83672</p></>}
            phone={"208-549-4424"}
            fax={"208-414-0947"}
            noTitle={props.noTitle}
        />
    )
}