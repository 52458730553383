import { IconButton, SwipeableDrawer } from "@material-ui/core";
import { Close, Facebook } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { FMCContactButton } from "../../../buttons/buttons";
import Logo from "../../../shared-components/logo/logo";
import MenuItems from "../menu-items/menu-items";
import { toggleMenu } from "../../../../redux/actions/actions";

import "../../../../../css/menu/menu.scss";

export default function Sidebar() {
  const menuState = useSelector((state) => state.menu.open);
  const dispatch = useDispatch();

  const setMenu = () => {
    dispatch({ type: toggleMenu, payload: !menuState });
  };

  return (
    <SwipeableDrawer
      swipeAreaWidth={0}
      open={menuState === true}
      anchor={"right"}
      onClose={setMenu}
      onOpen={setMenu}
    >
      <div className={"sidebar-root"}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <IconButton onClick={setMenu} style={{ margin: 10 }}>
            <Close color={"primary"} />
          </IconButton>

          <Logo onClick={setMenu} />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <a
              href={"https://www.facebook.com/WeiserMemorialHospital"}
              rel={"noreferrer"}
              target={"_blank"}
              style={{
                textDecoration: "none",
                color: "initial",
                padding: 0,
              }}
            >
              <IconButton>
                <Facebook color={"primary"} />
              </IconButton>
            </a>
          </div>
        </div>
        <div>
          <div className={"sidebar-contact-button"}>
            <FMCContactButton />
          </div>
        </div>
        <MenuItems />
      </div>
    </SwipeableDrawer>
  );
}
