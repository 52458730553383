import C2G from "../../components/c2g/c2g";
import BasePage from "../base-page/base-page";

export default function FP() {
  return (
    <BasePage title={"Family Practitioners"}>
      <C2G style={{ margin: "auto"}}/>
    </BasePage>
  );
}
