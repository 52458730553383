import { Fragment, useState } from "react";
import { Close } from "@material-ui/icons";
import {
  Button,
  Fade,
  Grid,
  ListItem,
  Menu,
  MenuItem,
} from "@material-ui/core";

import "../../../../css/treatment-list/treatment-list.scss";

export default function ListOfTreatments({ noLabel, ...rest }) {
  const [showContent, setShowContent] = useState([false, null, null]);
  const list = require("./data/list.json").sort((a, b) =>
    a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
  );

  return (
    <div className="list-of-treatments-root" {...rest}>
      {!noLabel && (
        <Fragment>
          <p>Some of the many services provided at Family Medical Center</p>
          <p style={{ fontSize: "0.9rem" }}>
            *click on a service to learn more
          </p>
        </Fragment>
      )}
      <Grid container className={"container"} justify={"center"} spacing={2}>
        <Grid item>
          {/* <h2 style={{ marginBottom: 0 }}>ACUTE CARE</h2>
          <p style={{ marginTop: 0, color: "white", background: "#232323" }}>
            *WALKINS WELCOME!
          </p> */}
          {list.slice(0, 10).map((s, i) => (
            <div key={i}>
              <Button
                onClick={(e) => setShowContent([true, s, e.currentTarget])}
                color="secondary"
              >
                {s.title}
              </Button>
            </div>
          ))}
        </Grid>
        <Grid item className="secondary-care">
          {/* <h2>SECONDARY CARE</h2> */}
          {list.slice(10, 20).map((s, i) => (
            <div key={i}>
              <Button
                onClick={(e) => setShowContent([true, s, e.currentTarget])}
                color="secondary"
              >
                {s.title}
              </Button>
            </div>
          ))}
        </Grid>
        <Grid item className={`service-list-item`}>
          {/* <h2>URGENT CARE</h2> */}
          {list.slice(20).map((s, i) => (
            <div key={i}>
              <Button
                onClick={(e) => setShowContent([true, s, e.currentTarget])}
                color="secondary"
              >
                {s.title}
              </Button>
            </div>
          ))}
        </Grid>
      </Grid>
      <Menu
        anchorEl={showContent[2]}
        open={showContent[0]}
        onClose={() => setShowContent([false, null, null])}
        keepMounted
        TransitionComponent={Fade}
      >
        <MenuItem
          className={"close-button"}
          onClick={() => setShowContent([false, null])}
        >
          <Close />
        </MenuItem>
        <MenuItem disabled>
          <h4
            style={{
              marginBottom: 0,
            }}
          >
            {showContent[1] && showContent[1].title}
          </h4>
        </MenuItem>
        <ListItem
          style={{
            maxWidth: 350,
          }}
        >
          {showContent[1] && (
            <p dangerouslySetInnerHTML={{ __html: showContent[1].content }} />
          )}
        </ListItem>
      </Menu>
    </div>
  );
}
