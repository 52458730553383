import { NavHashLink, genericHashLink } from "react-router-hash-link";

import { scrollWidthOffset } from "../../menu/main-menu/data/menu-items-data";

const CustomHashLink = ({ children, ...rest }) => {
  return (
    <NavHashLink {...rest} scroll={(el) => scrollWidthOffset(el)}>
      {children}
    </NavHashLink>
  );
};

const HashLink = genericHashLink(CustomHashLink);

export default HashLink;
