import { Card, Grid } from "@material-ui/core";

import BasePage from "../base-page/base-page";

import covidBG from "../../../assets/imgs/backgrounds/covid.png";
import ContactCard from "../../components/shared-components/contact-components/contact-card/contact-card";
import COVIDComponent from "../../components/covid-components/covid-component";
import ReactPlayer from "react-player";

export default function Covid() {
  const helmetData = require("../meta-data.json").covid;
  const vidURL = "https://fb.watch/8b1xXjpl-8/";

  return (
    <BasePage
      title={"COVID-19 Information"}
      header={{ src: covidBG, noTitle: true }}
      helmet={helmetData}
    >
      <>
        <div className={"covid-page-root"}>
          <h2 className={"primary-title"}>WHAT IS COVID-19?</h2>
          <p>
            COVID-19 is a respiratory condition caused by a coronavirus. Some
            people are infected but don’t notice any symptoms. Most people will
            have mild symptoms and get better on their own. But about 1 in 6
            will have severe problems, such as trouble breathing. The odds of
            more serious symptoms are higher if you’re older or have another
            health condition like diabetes or heart disease.
          </p>
        </div>
        <Grid item md={12}>
          <ReactPlayer
            url={vidURL}
            width={"500px"}
            controls
            style={{ margin: "0 auto" }}
          />
        </Grid>
        <h3 className="text-primary">
          Where and when can you get a COVID-19 test?
        </h3>
        <Grid container spacing={2} style={{ background: "aliceblue" }}>
          <Grid item md={4}>
            <Card>
              <ContactCard
                label="Weiser Memorial Hospital"
                phone="208-549-0370"
                address="645 5th Street, Weiser"
                href="645 5th Street, Weiser"
              />
              <small>
                <b>
                  <br /> **(MUST HAVE SYMPTOMS & REQ URGENT MEDICAL CARE)
                </b>
              </small>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card>
              <ContactCard
                label="Family Medical Center"
                phone="208-549-4424"
                address="360 East Liberty, Weiser"
                href="360 East Liberty, Weiser"
              />
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <ContactCard
                label="Two Rivers Medical Clinic"
                phone="208-549-0211"
              />
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <ContactCard
                label="St. Alphonsus/Fruitland"
                phone="208-381-9500"
              />
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <ContactCard label="St. Luke's Fruitland" phone="208-452-8050" />
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <ContactCard label="Rite Aid/Ontario" phone="541-889-3390" />
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <ContactCard label="Walgreens/Ontario" phone="541-889-6288" />
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <ContactCard
                label="Valley Family Health/Ontario"
                phone="541-889-2340"
              />
            </Card>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexFlow: "column",
            maxWidth: 800,
            margin: "0 auto",
          }}
        >
          <h2>COVID Testing Information</h2>
          <p>
            Weiser Memorial Hospital’s Emergency Department and Family Medical
            Center are facing mounting demands for COVID-19 testing, which is
            rapidly depleting testing supplies.
          </p>
          <p>
            Weiser Memorial Hospital{" "}
            <u>
              <b>is not</b>
            </u>{" "}
            testing if you do not have symptoms. Weiser Memorial Hospital{" "}
            <u>
              <b>is not</b>
            </u>{" "}
            testing people who are wanting a test for travel or to attend an
            event.
          </p>
          <p>
            If you are ill and need urgent medical care, go to the emergency
            department. A medical professional will evaluate you to determine if
            you need to be tested for COVID-19.
          </p>
          <p>
            If you have mild Covid symptoms, and do not need urgent or emergency
            medical care, please do not come to the emergency
            department/Hospital for a Covid test. See testing site options
            below.
          </p>
        </div>
        <COVIDComponent />
      </>
    </BasePage>
  );
}
