import { List, ListItemText } from "@material-ui/core";

import NoticeBanner from "../../banners/notice-banner";

export default function Hours({ hoursOnly, ...rest }) {
  return (
    <div {...rest}>
      {!hoursOnly && <h3 className={"primary-title overlined"}>Hours</h3>}
      <List>
        <ListItemText>
          <b>Monday - Friday:</b> 8 A.M. - 5 P.M.
        </ListItemText>
        <ListItemText>
          <b>Thursdays:</b> 8 A.M. - 7 P.M.
        </ListItemText>
      </List>
      {!hoursOnly && (
        <NoticeBanner
          content={
            <p className={"danger"}>
              *PLEASE ARRIVE 15 MINUTES BEFORE YOUR APPOINTMENT TO COMPLETE THE
              NECESSARY FORMS. THANK YOU.
            </p>
          }
        />
      )}
    </div>
  );
}
