import { Button, Paper } from "@material-ui/core";
import { useDispatch } from "react-redux";

import "../../../../css/downloadable-forms/downloads.scss";

export function DownloadsButton(props) {
  const dispatch = useDispatch();

  const openDownloads = () => {
    dispatch({ type: "SET_SHOW_DOWNLOADS_LIST", payload: true });
  };

  return (
    <div className={"downloads-button"}>
      <Button {...props} onClick={openDownloads}>
        Patient Forms
      </Button>
      *Download Patient Forms
    </div>
  );
}

export function Downloads({ btnonly, ...rest }) {

  if(btnonly) return <DownloadsButton {...rest} />
  
  return (
    <Paper className={"downloads-root"}>
      <h2>Patient Forms & Downloads</h2>
      <h3 className={"grad-text-dark overlined"}>Incoming Record Request</h3>
      <p>
        Use this form to give your previous provider or provider's permission to
        send copies of your medical records to Family Medical Center. If we
        receive those records before you have come in for an appointment to
        establish care with one of our providers.
      </p>
      <h3 className={"grad-text-dark overlined"}>Outgoing Record Request</h3>
      <p>
        Use this form to have copies of your medical records from Family Medical
        Center sent to your new provider(s) office. If you want to have them
        sent directly to you, there will be a fee. All additional requests will
        be copied for a fee.
      </p>
      <DownloadsButton {...rest} />
    </Paper>
  );
}
