import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { Paper } from "@material-ui/core";

import Stepper from "./stepper/stepper";
import Step from "./step/step";
import { steps } from "./data/stepContent";

import logo from "./assets/FMC_logo.svg";

import "./c2g.scss";

export default function C2G({ ...rest }) {
  const [activeStep, setActiveStep] = useState(0);

  const totalSteps = steps.length;

  const stepperState = {
    totalSteps: totalSteps,
    activeStep: activeStep,
    setActiveStep: setActiveStep,
    stepData: steps[activeStep],
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Paper className="c2g-root" {...rest}>
      <img
        src={logo}
        alt={"fmc logo"}
        style={{ maxWidth: "35%", }}
      />
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {steps.map((s) => (
          <Step {...s} />
        ))}
      </SwipeableViews>
      <Stepper {...stepperState} />
    </Paper>
  );
}
