import {
  Grid,
} from "@material-ui/core";
import ServiceCard from "./service-card/service-card";
import "../../../../css/services/quick-care.scss";
import { quickCareData } from "./data/quick-care-data";

export default function QuickCare(props) {

  return (
    <div className={`quick-care-root ${props.column ? "column" : false}`}>
      <Grid
        container
        justify={"space-around"}
        spacing={2}
        className={`care-cards-root`}
      >
        {quickCareData.map((s, i) => (
          <Grid item key={i} className={`quick-care-item`} md={12}>
            <div className={s.title ? s.title.split(" ").join("-") : null}>
              <ServiceCard {...s} noLink small />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
