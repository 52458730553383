import { Fragment, useState } from "react";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { Button, Grid, Grow } from "@material-ui/core";

import FMCContactCard from "../../../contact-components/contact-selector/contact-card/fmc-contact-card";

import "../../../../../../css/providers/profile-tab.scss";
import { useDispatch, useSelector } from "react-redux";
import { setProviderData } from "../../../../../redux/actions/actions";

export default function ProfileTab({ profile, children }) {
  const showAllInfo = useSelector((state) => state.providerDataOpen);
  const dispatch = useDispatch();

  const setShowAllInfo = (value) => {
    dispatch({ type: setProviderData, payload: value });
  };

  return (
    <div className={"profile-tab-root"}>
      <Grid container className={"grid-root"} spacing={3}>
        {children && (
          <Grid item md={12} className={"content-section"}>
            {children}
          </Grid>
        )}
        <Grid item md={12}>
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={() => setShowAllInfo(!showAllInfo)}
            endIcon={showAllInfo ? <ArrowUpward /> : <ArrowDownward />}
          >
            {showAllInfo ? "Less" : "More"} about {profile.name}
          </Button>
        </Grid>
        <Grow in={showAllInfo} unmountOnExit>
          <Grid container className={"grid-root"} spacing={3}>
            <Grid item md={4} className={"contact-section"}>
              <h4 className={"primary-title"}>CONTACT INFO:</h4>
              <FMCContactCard />
            </Grid>
            {profile.education && (
              <Grid item md={4} className={"education-section"}>
                <h4 className={"primary-title"}>EDUCATION:</h4>
                {Array.isArray(profile.education) &&
                  profile.education.map((school, i) => (
                    <p key={i * 0.002}>{school}</p>
                  ))}
                  {profile.residency&&<p><b>Residency -</b> {profile.residency}</p>}
              </Grid>
            )}
            {profile.certifications && (
              <Grid item md={4} className={"certification-section"}>
                <h4 className={"primary-title"}>CERTIFICATIONS:</h4>
                {Array.isArray(profile.certifications) &&
                  profile.certifications.map((cert, i) => (
                    <p key={i}>{cert}</p>
                  ))}
              </Grid>
            )}
          </Grid>
        </Grow>
      </Grid>
    </div>
  );
}
