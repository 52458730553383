import { Button, MobileStepper } from "@material-ui/core";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";

export default function Stepper({
  totalSteps,
  activeStep,
  setActiveStep,
  stepData,
  ...rest
}) {
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <MobileStepper
      variant="dots"
      steps={totalSteps}
      position="static"
      activeStep={activeStep}
      className="stepper-root"
      nextButton={
        <Button
          size="small"
          onClick={handleNext}
          disabled={activeStep === totalSteps - 1}
          className="nex-btn"
        >
          Next
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft />
          Back
        </Button>
      }
    />
  );
}
