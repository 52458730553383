import {
  about,
  appointments,
  behavioralHealth,
  billing,
  contact,
  findUs,
  home,
  // immunizations,
  maternity,
  meetProviders,
  primaryCare,
  patientRights,
} from "../../../../routes/paths";

export const links = [
  {
    title: "Home",
    link: home + "#",
  },
  {
    title: "Services",
    subs: [
      {
        title: "Primary Care",
        link: primaryCare,
      },
      {
        title: "Behavioral Health",
        link: behavioralHealth,
      },
      {
        title: "Maternity Care",
        link: maternity,
      },
    ],
  },
  {
    title: "Information",
    subs: [
      {
        title: "Find Us",
        link: findUs,
      },
      {
        title: "Appointments",
        link: appointments,
      },
      {
        title: "Billing",
        link: billing,
      },
      {
        title: "Your Providers",
        link: meetProviders,
      },
      {
        title: "Patient Rights/Complaints",
        link: patientRights,
      },
    ],
  },
  {
    title: "Contact",
    link: contact + "#",
  },
  {
    title: "About",
    link: about + "#",
  },
];

export const scrollWidthOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -120;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};
