import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { ArrowBackOutlined } from "@material-ui/icons";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BasePage from "../base-page/base-page";

export default function PageNotFound404() {
  useEffect(() => (document.title = "404 Page Not Found"));
  const history = useHistory();

  return (
    <BasePage>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="ERROR 404: We can't find this page" />
      </Helmet>
      <div
        style={{
          height: "55vh",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>ERROR 404</h1>
        <h2>
          Oops, it looks like the page your looking for has been moved or
          doesn't exist.
        </h2>
        <Button onClick={() => history.push("/#")}>
          <ArrowBackOutlined />
          Home
        </Button>
      </div>
    </BasePage>
  );
}
