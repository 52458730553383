import { Container, Typography } from "@material-ui/core";
import { RoomService } from "@material-ui/icons";
import { Helmet } from "react-helmet-async";
import "../../../css/pages/base-page.scss";

export default function BasePage({ title, icon, header, children, helmet, ...rest }) {
  const PageHeader = ({ src, noTitle, ...rest }) => (
    <div className={"base-page-header-root"}>
      {src && <img alt={"Page Header"} src={src} />}
      {!noTitle && (
        <div className={"header-content"}>
          <Typography
            component={"h1"}
            className={"header-title"}
          >
            {icon} {title}
          </Typography>
        </div>
      )}
    </div>
  );

  return (
    <Container maxWidth={"lg"} className={"base-page-root"}>
      {helmet && helmet.title && (
        <Helmet>
          <title>{helmet.title}</title>
          {helmet.description && (
            <meta name="description" content={helmet.description} />
          )}
          {helmet.link && <link rel="canonical" href={helmet.link} />}
        </Helmet>
      )}
      <PageHeader {...header} {...rest} />
      <div className={"page-content"}>{children}</div>
    </Container>
  );
}
