import { Fragment } from "react";
import { Button, Grid, Hidden, Paper } from "@material-ui/core";

import WalkinsWelcome from "../../../components/shared-components/walkins-welcome/walkins-welcome";
import {
  Downloads,
  DownloadsButton,
} from "../../../components/shared-components/downloadable-forms/downloads";
import Contact from "../../contact/contact";
import NoticeBanner, {
  AfterHoursNotice,
} from "../../../components/shared-components/banners/notice-banner";
import { FMCContactButton } from "../../../components/buttons/buttons";
import MeetTheTeam from "../../../components/shared-components/meet-your-providers/meet-your-providers";
import { DateRange } from "@material-ui/icons";
import PatientRights from "./patient-rights";
import SlidingScale from "./sliding-scale-table";

export const informationInfo = [
  {
    title: "Find Us",
    content: (
      <Grid item md={12}>
        <Contact noTitle noHelmet />
      </Grid>
    ),
  },
  {
    title: "Appointments",
    content: (
      <Grid container spacing={3}>
        <Grid item md={6} className={"item"}>
          <Paper>
            <Fragment>
              <h3 className={"grad-text-dark overlined"}>
                IS AN APPOINTMENT NECESSARY?
              </h3>
              <NoticeBanner
                content={<h2>Make An Appointment Online Soon!</h2>}
                icon={<DateRange />}
              />
              <p>
                When visiting Family Medical Center, we recommend you make an
                appointment. We do take same day appointments and walk-in
                patients.
              </p>
            </Fragment>
            <WalkinsWelcome noButtons />
            <div style={{ margin: 20 }}>
              {" "}
              <DownloadsButton
                btnonly="true"
                color={"primary"}
                variant={"contained"}
              />
            </div>
            <FMCContactButton />
          </Paper>
        </Grid>
        <Grid item md={6} className={"item meet-the-team"}>
          <MeetTheTeam />
        </Grid>
        <AfterHoursNotice />
      </Grid>
    ),
  },
  {
    title: "Billing",
    content: (
      <Fragment>
        <Grid item md={8} className={"item"}>
          <Paper
            className={"insurance"}
            style={{ padding: 5, background: "rgba(255,255,255,0.75)" }}
          >
            <h3 className={"grad-text-dark overlined"}>BILLING PROCEDURES</h3>
            <p>
              To help keep the cost of treatment down, we ask that you pay for
              your visit at the time of service. Visa, MasterCard, American
              Express, and the Discover Card are welcome. If you have a large
              bill or financial difficulties, our our financial counselor and
              billing staff will assist you in making special arrangements. For
              more information go to the{" "}
              <a
                href={"https://weisermemorialhospital.org/information#billing"}
                target={"__blank"}
              >
                Weiser Memorial Hospital website
              </a>
              .
            </p>
            <p>
              Our family of providers function as a group. It is in your best
              interest to see your provider whenever you come to the Clinic.
              However, with our group concept, you and your family have the
              option of seeing one of our other providers should your provider
              be out of the Clinic or delayed by an emergency. It is also our
              philosophy that the relationship between you and your provider is
              one of utmost importance. If you should wish to change to one of
              the Clinic's other providers, we encourage and support your
              decision, and ask you to notify the receptionist to make the
              change.
            </p>
            <h4 className={"grad-text-dark overlined"}>INSURANCE</h4>
            <h4>
              If you have insurance, please be prepared to present your
              insurance identification card, billing information and pay your
              deductible or co-pay amounts at the time of service. It is your
              responsibility to inform us of any changes in insurance coverages.
              Out of date information may result in our office billing you
              directly.
            </h4>
            <p>
              We will gladly bill any of the plans listed below for you.
              However, some plans may not be open to our new patients. Please
              call for details. If you have problems or questions about
              insurance matters, call us and we will try to help you in any way
              that we can.
            </p>
            <p>
              We bill secondary insurances for our patients. If you have a
              secondary, please let the receptionist know at the time you check
              in and provide your insurance card.
            </p>
          </Paper>
        </Grid>
        <Hidden smDown>
          <Grid item md={4}>
            <Downloads color={"primary"} variant={"contained"} />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Downloads color={"primary"} variant={"contained"} btnonly />
        </Hidden>

        <Grid item x2={12}>
          <h3 style={{ marginBottom: 0, color: "white" }}>
            Our sliding discount fee program.
          </h3>
          <div style={{ width: "88vw", maxWidth: 710, overflow: "auto" }}>
            <SlidingScale />
          </div>
          <Button
            type="link"
            color="secondary"
            href={
              process.env.PUBLIC_URL +
              "/downloads/wmh-sliding-fee-discount-program.pdf"
            }
            target={"_blank_"}
          >
            <Button component="h3" style={{ marginTop: 0 }}>
              Learn More.
            </Button>
          </Button>
        </Grid>
      </Fragment>
    ),
  },
  {
    title: "Patient Rights",
    content: <PatientRights />,
  },
];
