import ScrollToTop from "react-scroll-to-top";
import { KeyboardArrowUp } from "@material-ui/icons";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import { Routes } from "./js/routes/routes";
import MainMenu from "./js/components/menu/main-menu/main-menu";
import Footer from "./js/components/footer/footer";
import ModalBase from "./js/components/popups/popup";

import "./App.scss";
import OutdatedBrowser from "./js/pages/outdated-browser";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const theme = createMuiTheme({
  typography: {
    fontFamily: `"Karla", "sans-serif"`,
  },
  palette: {
    primary: {
      main: "#002b5c",
      rgb: "0, 43, 92",
    },
    secondary: {
      main: "#9fcbed",
      rgb: "159, 203, 237",
    },
    lightSecondary: {
      main: "rgba(159, 203, 237, 0.5)",
    },
    third: {
      main: "#e02f07",
    },
  },
});

function App() {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const isIEState = useSelector(state => state.isIE);
  const dispatch = useDispatch();  

  useEffect(() => {
    isIE && dispatch({ type: "SET_isIE", payload: true });
  }, [isIE, dispatch]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ModalBase />
          <MainMenu />
          {!isIEState ? <Routes /> : <OutdatedBrowser />}
          <Footer />
          <ScrollToTop smooth component={<KeyboardArrowUp />} />
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
