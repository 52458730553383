import { makeStyles, Paper } from "@material-ui/core";
import { Phone, Print } from "@material-ui/icons";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  address: {
    "& p": {
      margin: 0,
      textAlign: "center",
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function ContactCard({
  label,
  href,
  address,
  fax,
  email,
  phone,
  tail,
  noTitle,
}) {
  const classes = useStyles();

  return (
    <div className={classes.address}>
      {!noTitle && (
        <h4 style={{ marginBottom: 0, textAlign: "center" }}>{label}</h4>
      )}
      {address &&
        (href ? (
          <a href={`https://maps.google.com/maps?q=${href}`}>{address}</a>
        ) : (
          address
        ))}
      <Paper style={{ padding: 5, background: "aliceblue" }} elevation={0}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Phone />
          <b>
            <a href={`tel:+1${phone.replace("-", "")}`}>{`(${
              phone.split("-", 1)[0]
            }) ${phone.split("-")[1]}-${phone.split("-")[2]}`}</a>
          </b>
        </div>
        {fax && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Print />
            <b>{`(${fax.split("-", 1)[0]}) ${fax.split("-")[1]}-${
              fax.split("-")[2]
            }`}</b>
          </div>
        )}
        {email && (
          <Fragment>
            <a href={`mailto:${email}`}>{email}</a>
          </Fragment>
        )}
        {tail && <p>{tail}</p>}
      </Paper>
    </div>
  );
}
