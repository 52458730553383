import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grow, Icon, IconButton } from "@material-ui/core";
import {
  Help,
  Facebook,
  OpenInNew,
  Payment,
  Phone,
  RoomService,
} from "@material-ui/icons";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  about,
  appointments,
  billing,
  contact,
  covid,
  services,
  findUs,
  home,
  maternity,
  behavioralHealth,
  primaryCare,
  meetProviders,
  patientRights,
} from "../../../../routes/paths";
import HashLink from "../../../shared-components/custom-hash-link/hash-link";
import Translate from "./translate/translate";

import "../../../../../css/menu/menu.scss";

const linkList = [
  {
    component: "button",
    title: "SERVICES",
    icon: <RoomService />,
    subItems: [
      {
        title: "Primary Care",
        to: primaryCare,
      },
      {
        title: "Behavior Health",
        to: behavioralHealth,
      },
      {
        title: "Maternity Care",
        to: maternity,
      },
    ],
  },
  {
    component: "button",
    title: "INFORMATION",
    icon: <Help />,
    subItems: [
      {
        title: "Find Us",
        to: findUs,
      },
      {
        title: "Appointments",
        to: appointments,
      },
      {
        title: "Billing",
        to: billing,
      },
      {
        title: "Your Providers",
        to: meetProviders,
      },
      {
        title: "About FMC",
        to: about + "#",
      },
      {
        title: "Patient Rights/Complaints",
        to: patientRights,
      },
      {
        title: "COVID Info",
        to: covid + "#",
      },
    ],
  },
  {
    component: "a",
    href:
      "https://zb.rpropayments.com/Login/CheckOutFormLogin/ZXtUJ5Nc-cu9Qzg-Uv2Uf2Wm-G4-",
    title: (
      <span className={"ext-link"}>
        PAY A BILL
        <Icon style={{ display: "flex", width: 15 }}>
          <OpenInNew />
        </Icon>
      </span>
    ),
    icon: <Payment />,
  },
  {
    component: "a",
    href: "https://mycarecorner.net/Default.aspx",
    title: (
      <span className={"ext-link"}>
        PATIENT PORTAL
        <Icon style={{ display: "flex", width: 15 }}>
          <OpenInNew />
        </Icon>
      </span>
    ),
    // icon: <Payment />,
  },
];

export default function MenuBarTop() {
  const TopBarButton = ({
    to,
    href,
    icon,
    title,
    subItems,
    component,
    ...rest
  }) => {
    const [openSub, setOpenSub] = useState([false, []]);
    const dispatch = useDispatch();

    const setSubs = () => {
      setOpenSub([true, subItems]);
    };

    const closeSubs = () => {
      setOpenSub([false, []]);
    };

    return (
      <ClickAwayListener onClickAway={closeSubs} {...rest}>
        <div className={"top-button-container"}>
          <Button
            variant={"text"}
            className={"link-icon"}
            component={component ? component : HashLink}
            to={to ? to : home + "#"}
            href={href ? href : null}
            // target={href ? "__blank" : null}
            onClick={setSubs}
            startIcon={icon}
          >
            <span className={`button-title`}>{title}</span>
          </Button>
          <Grow in={openSub[0]}>
            <div className={"submenu-container"}>
              {Array.isArray(openSub[1]) &&
                openSub[1].map((s, i) => {
                  const component = s.func ? Button : HashLink;
                  const onClick = s.func ? () => dispatch(s.func) : closeSubs;

                  return (
                    <Button
                      component={component}
                      to={s.to}
                      onClick={onClick}
                      key={i}
                    >
                      {s.title}
                    </Button>
                  );
                })}
            </div>
          </Grow>
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <div className={"menu-top-bar"}>
      <div style={{ marginLeft: 20 }} className={"translate"}>
        <Translate />
      </div>
      <div className={"button-group"}>
        {linkList.map((l, i) => (
          <TopBarButton {...l} key={i} />
        ))}
        <IconButton component={HashLink} to={contact}>
          <Phone />
        </IconButton>
        <a
          href={"https://www.facebook.com/WeiserMemorialHospital"}
          rel={"noreferrer"}
          target={"_blank"}
          style={{
            textDecoration: "none",
            color: "initial",
            padding: 0,
          }}
        >
          <IconButton>
            <Facebook color={"primary"} />
          </IconButton>
        </a>
      </div>
    </div>
  );
}
