import { Grid, Paper } from "@material-ui/core";
import { Fragment } from "react";

import { TreatmentListBtn } from "../../../components/buttons/buttons";
import Hours from "../../../components/shared-components/contact-components/hours/hours";
import MeetTheTeam from "../../../components/shared-components/meet-your-providers/meet-your-providers";
import SquareImgLinks from "../../../components/shared-components/square-img-links/square-img-links";
import Logo from "../../../components/shared-components/logo/logo";

import leena from "../../../../assets/imgs/portraits/lenna.jpg";

export const aboutInfo = [
  {
    title: "About Family Medical Center",
    content: (
      <Fragment>
        <Grid item md={8}>
          <Paper style={{ padding: 5 }}>
            <Logo />
            <h3>
              At Family Medical Center you will find that our staff is committed
              to making sure you have access to the care you need, when you need
              it. We welcome walk-ins and same day appointments.
            </h3>
            <TreatmentListBtn variant={"contained"} color={"primary"} />
            <Grid
              container
              spacing={3}
              style={{ marginTop: 10 }}
              justify={"center"}
            >
              <Grid item md={6}>
                <img src={leena} alt={"Lenna opening the door"} />
              </Grid>
              <Grid item md={6}>
                <p>
                  We offer personalized family care with the convenience of a
                  medical lab, x-ray, ultra-sound, and CT scanning less than a
                  block away. Our same-day appointments and extended clinic
                  hours assure that you and your family can access quality care
                  whenever you need it.
                </p>
                <p>
                  Our family of providers function as a group. It is in your
                  best interest to see your provider whenever you come to the
                  Clinic. However, with our group concept, you and your family
                  have the option of seeing one of our other providers should
                  your provider be out of the Clinic or delayed by an emergency.
                  It is also our philosophy that the relationship between you
                  and your provider is one of utmost importance. If you should
                  wish to change to one of the Clinic's other providers, we
                  encourage and support your decision, and ask you to notify the
                  receptionist to make the change.
                </p>
              </Grid>
            </Grid>
            <Hours />
          </Paper>
        </Grid>
        <Grid item md={4}>
          <SquareImgLinks smallLinks />
        </Grid>
      </Fragment>
    ),
  },
  {
    title: "Meet Your Providers",
    noTitle: true,
    content: <MeetTheTeam />,
  },
];
