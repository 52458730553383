export default function Map() {

  return (
      <iframe
        title={"Family Medical Center Map"}
        style={{ marginTop: 20, maxWidth: "100%" }}
        width="400"
        height="500"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=360%20East%20Liberty%20Street%20Weiser,%20ID%2083672&t=&z=15&ie=UTF8&iwloc=&output=embed"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      />
  );
}
