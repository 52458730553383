import { Fragment } from "react";

import { TreatmentListBtn } from "../../../buttons/buttons";
import {
  behavioralHealth,
  maternity,
  primaryCare,
} from "../../../../routes/paths";

// import bhProviders from "../../../../../assets/imgs/portraits/behavioral-health-providers.jpg";
import pcProviders from "../../../../../assets/imgs/portraits/primary_care.jpg";
import blanchardMaternity from "../../../../../assets/imgs/portraits/blanchard-maternity.jpg";
import bhProviders from "../../../../../assets/imgs/portraits/behavioral-health.jpg";

const links = [
  {
    src: pcProviders,
    linkStyles: {
      backgroundPosition: "0 22.5px",
    },
    link: primaryCare,
    title: "Primary Care",
    content: {
      content: (
        <Fragment>
          <p>
            We offer personalized family care with the convenience of a medical
            lab, x-ray, ultra-sound, and CT scanning less than a block away.
          </p>
          <TreatmentListBtn
            color={"secondary"}
            variant={"contained"}
            style={{ margin: "0 auto" }}
          />
        </Fragment>
      ),
    },
  },
  {
    src: bhProviders,
    link: behavioralHealth,
    title: "Behavioral Health",
    content: {
      content: (
        <Fragment>
          <p>
            At Family Medical Center we are happy to offer behavioral health
            services to our patients. Treatment for behavioral health issues is
            an important part of a balanced well-being.
          </p>
        </Fragment>
      ),
    },
  },
  {
    src: blanchardMaternity,
    linkStyles: {
      backgroundPosition: "0 40px",
    },
    link: maternity,
    title: "Maternity Care",
    content: {
      content: (
        <Fragment>
          <p>
            Our doctors are passionate about delivering babies and will work
            with you, and listen to what you want, so that you can have the best
            possible birth experience.
          </p>
        </Fragment>
      ),
    },
  },
];

export default links;
