import { Grid, Paper } from "@material-ui/core";

import Map from "../../components/shared-components/map/map";
import Hours from "../../components/shared-components/contact-components/hours/hours";
import FMCContactCard from "../../components/shared-components/contact-components/contact-selector/contact-card/fmc-contact-card";
import { FMCContactButton } from "../../components/buttons/buttons";
import BasePage from "../base-page/base-page";

import entranceSign from "../../../assets/imgs/backgrounds/front-sign.jpg";

import "../../../css/pages/contact.scss";
import { Phone } from "@material-ui/icons";

export default function Contact({ title, noHelmet, ...rest }) {
  const helmetData = require("../meta-data.json").contact;

  return (
    <BasePage
      title={!title ? "Contact Us" : title}
      icon={<Phone />}
      helmet={!noHelmet ? helmetData : undefined}
      {...rest}
    >
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item md={6}>
          <Paper className={"contact-card"}>
            <FMCContactCard />
            <Hours />
            <img src={entranceSign} alt={"FMC Entrance Sign"} />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <FMCContactButton />
          <Paper style={{ padding: 10 }}>
            <h3 style={{ marginBottom: 0 }}>
              Family Medical Center can be found at 360 East Liberty. The main
              doors to the clinic are wheelchair accessible.
            </h3>
            <Map />
          </Paper>
        </Grid>
      </Grid>
    </BasePage>
  );
}
