import { Paper } from "@material-ui/core";

export default function Step({ title, content, img, ...rest }) {
  return (
    <Paper className="step-root" {...rest}>
      <h2>{title}</h2>
      <div className="step-content">{content}</div>
      {img && <img src={img} alt="slide animation" />}
    </Paper>
  );
}
