import { Fragment } from "react";
import { Grid, Hidden, List, ListItemText, Paper } from "@material-ui/core";

import ListOfTreatments from "../../../components/shared-components/list-of-treatments/list-of-treatments";
import VisitTheER from "../../../components/shared-components/visit-the-er/visit-the-er";
import SquareImgLinks from "../../../components/shared-components/square-img-links/square-img-links";
import ContactCard from "../../../components/shared-components/contact-components/contact-selector/contact-card/contact-card";

import links from "./maternity-square-data";
import FMCImage from "../../../components/shared-components/FMC-img-card/FMC-img";
import {
  AliImg,
  GallegosImg,
  HathhornImg,
} from "../../../components/shared-components/doctor-images/doctor-images";

import bhProviders from "../../../../assets/imgs/portraits/behavioral-health-providers.jpg";
import babyYawn from "../../../../assets/imgs/portraits/baby-yawn.jpg";
import babyFeet from "../../../../assets/imgs/portraits/baby-feet.jpg";
import babySleeping from "../../../../assets/imgs/portraits/baby-sleeping.jpg";
import { PractitionerBtn } from "../../../components/buttons/buttons";

export const serviceItems = [
  {
    title: "Primary Care",
    content: (
      <Fragment>
        <Grid item md={8} className={"content"}>
          <Paper style={{ padding: 10 }}>
            <h2 className={"grad-text-dark overlined"}>
              CARING FOR YOUR FAMILY
            </h2>
            <h3>
              At Family Medical Center you will find that our staff is committed
              to making sure you have access to the care you need, when you need
              it. We welcome walk-ins and same day appointments.
            </h3>
            <PractitionerBtn
              variant={"contained"}
              color={"primary"}
              style={{ marginBottom: 10 }}
            />
            <div className={"fmc-image"}>
              <FMCImage
                pcProvidersImg={true}
                style={{ justifyContent: "space-around", alignItems: "center" }}
              />
            </div>
            <ListOfTreatments style={{ backgroundColor: "#002b5c" }} />
          </Paper>
        </Grid>
        <Grid item md={4}>
          <VisitTheER />
        </Grid>
      </Fragment>
    ),
  },
  // Behavioral Health Section
  {
    title: "Behavioral Health",
    content: (
      <Fragment>
        <Grid item md={12} className={"content"}>
          <Paper style={{ padding: 10 }}>
            <img src={bhProviders} alt="Behavioral Health Specialists" />
            <hr />
            <h3>WELCOME BEHAVIORAL HEALTH SPECIALIST</h3>
            <h2>Ali Capurro, LPC</h2>
            <Grid container justify={"center"} alignItems={"center"}>
              <Grid item md={6}>
                <p>
                  Please help us welcome Ali Capurro, LPC to the staff at FMC!
                  Ali will work with individuals, couples, and families to
                  coordinate comprehensive care including working alongside
                  patient's medical and psychiatric team with the goal of
                  progressing toward overall well-being.
                </p>
              </Grid>
              <Grid item md={6}>
                <div className={"doctor-intro"}>
                  <AliImg noSmall style={{ color: "black" }} />
                  <ContactCard
                    label={"Ali Capurro, LPC"}
                    href={"360 East Liberty Weiser, ID 83672"}
                    address={
                      <>
                        <p>360 East Liberty</p> <p>Weiser, ID 83672</p>
                      </>
                    }
                    phone={"208-549-4424"}
                    fax={"208-414-0947"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              justify={"center"}
              alignItems={"center"}
              style={{ margin: 10 }}
            >
              <Grid item md={6}>
                <div className={"doctor-intro"}>
                  <GallegosImg noSmall style={{ color: "black" }} />
                  <ContactCard
                    label={"Stephanie Gallegos, LMSW"}
                    href={"360 East Liberty Weiser, ID 83672"}
                    address={
                      <>
                        <p>360 East Liberty</p> <p>Weiser, ID 83672</p>
                      </>
                    }
                    phone={"208-549-4424"}
                    fax={"208-414-0947"}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <p>
                  Stephanie works with all the medical providers in conjunction
                  with patients to address and improve behavioral issues.
                </p>
              </Grid>
            </Grid>
          </Paper>
          {/* <Paper style={{ padding: 10 }}>
            <h2>MELISSA HATHORN, PMHNP-BC</h2>
            <Grid container justify={"center"} alignItems={"center"}>
              <Grid item md={6}>
                <div className={"doctor-intro"}>
                  <HathhornImg noSmall style={{ color: "black" }} />
                  <ContactCard
                    label={"MELISSA HATHHORN, PMHNP-BC"}
                    href={"360 East Liberty Weiser, ID 83672"}
                    address={
                      <>
                        <p>360 East Liberty</p> <p>Weiser, ID 83672</p>
                      </>
                    }
                    phone={"208-549-4424"}
                    fax={"208-414-0947"}
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <p>
                  “Residing and being employed in rural Idaho as a nurse for
                  over 10 years, I had seen personally how limited access to
                  specialized psychiatric care affects patients and their
                  families. I look forward to having the opportunity to fullfil
                  my purpose to serve the Weiser community in the role of a
                  mental health provider.”
                </p>
              </Grid>
            </Grid>
          </Paper> */}
        </Grid>
      </Fragment>
    ),
  },
  {
    title: "Maternity Care",
    content: (
      <div className={"maternity-root content"}>
        <h1 className={"grad-text-light overlined"}>
          Family Medical Maternity
        </h1>
        <h3>
          Pregnancy is a very wonderful time. We offer everything you need to
          give your new loved one his/or her best start possible. Our doctors
          are passionate about delivering babies and will work with you, and
          listen to what you want, so that you can have the best possible birth
          experience.
        </h3>
        <Hidden smDown>
          <div className={"images"}>
            <img src={babyYawn} alt={"Baby Yawning"} />
            <img src={babySleeping} alt={"Baby Sleeping"} />
            <img src={babyFeet} alt={"Baby Feet"} />
          </div>
        </Hidden>
        <SquareImgLinks linkProps={links} bgColor={"#563d5e"} rounded />
      </div>
    ),
  },
];
