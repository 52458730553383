import BasePage from "../base-page/base-page";
import "../../../css/pages/information.scss";
import ContentComponents from "../base-page/content-component";
import { informationInfo } from "./data/information-component-data";
import { Help } from "@material-ui/icons";

export default function Information() {
  const helmetData = require("../meta-data.json").information

  return (
    <BasePage title={"Information"} icon={<Help />} helmet={helmetData}>
      <ContentComponents content={informationInfo} noButtonBar />
    </BasePage>
  );
}
