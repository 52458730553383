import ProfileTab from "./profile-tab/profile-tab";
import { Icon } from "@material-ui/core";
import { FormatQuote } from "@material-ui/icons";

import "../../../../../css/providers/profile.scss";

export default function Profile({ profile }) {
  return (
    <div className={"profile-root"}>
      <ProfileTab profile={profile}>
        <h2 style={{ marginBottom: 0 }}>{profile.name}</h2>
        <h3 style={{ marginTop: 0 }} className="primary-title overlined">
          - {profile.category}
        </h3>
        <blockquote>
          <Icon>
            <FormatQuote />
          </Icon>
          {profile.quote}
        </blockquote>
      </ProfileTab>
    </div>
  );
}
