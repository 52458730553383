import { Paper } from "@material-ui/core";
import HashLink from "../../custom-hash-link/hash-link";
import "../../../../../css/services/services.scss";
import { services } from "../../../../routes/paths";

export default function ServiceCard({
  title,
  img,
  children,
  small,
  href,
  noLink,
}) {
  return (
    <Paper className={`service-card-root ${small && "small"}`}>
      {!noLink ? (
        <HashLink to={href ? href : services}>
          <h2>{title.toUpperCase()}</h2>
        </HashLink>
      ) : (
        <h4>{title.toUpperCase()}</h4>
      )}

      {img && <img src={img} alt={title} />}
      {children}
    </Paper>
  );
}
