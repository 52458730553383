import { Container } from "@material-ui/core";
import StorySelector from "./components/story-selector/story-selector";
import "../../../css/mother-story/mothers-story.scss";
import { useSelector } from "react-redux";
import Story from "./components/story/story";

export default function BirthStory(props) {
  const selectedStory = useSelector((state) => state.currentBirthStory);

  return (
    <Container
      maxWidth={"lg"}
      className={`mothers-story-root ${
        selectedStory.gender && selectedStory.gender
      }`}
      id={"patients-story"}
    >
      <div className={"title-container"}>
        <h1>Family Medical Birth Stories</h1>
      </div>
      <StorySelector />
      {selectedStory.story && <Story {...selectedStory} />  }
    </Container>
  );
}
