import { useState } from "react";
import { AppBar, Box, Paper, Tab, Tabs, makeStyles } from "@material-ui/core";

import Profile from "./profile/profile";
import profiles, {
  BEHAVIORAL_HEALTH,
  FAMILY_PRACTICE_PROVIDER,
  NURSE_PRACTITIONER,
} from "./data/profiles";

import "../../../../css/providers/meet-your-providers.scss";

export default function MeetTheTeam() {
  const [value, setValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`provider-${index}`}
        aria-labelledby={`provider-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const useStyles = makeStyles({
    doctorTabs: {
      [`&.tab-${value} .MuiTabs-indicator`]: {
        background:
          profiles[value].className === "behavioral"
            ? "whitesmoke !important"
            : "#9fcbed",
        "&::before": {
          fontSize: "1.14em",
          content: `"${profiles[value].name}"`,
        },
      },
    },
  });

  const styles = useStyles();

  return (
    <Paper
      className={"meet-the-team-root"}
      elevation={3}
      id={"meet-your-providers"}
      style={{
        maxWidth: "100%",
        flexGrow: 1,
        background:
          profiles[value].className === "np"
            ? "#9fcbed"
            : profiles[value].className === "behavioral"
            ? "#a6d1b3dc"
            : "white",
        transition: "1s ease all",
      }}
    >
      <h1>Meet Your Providers</h1>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          aria-label="meet the providers tabs"
          className={`doctor-tabs ${styles.doctorTabs} tab-${value}`}
          variant="scrollable"
          scrollButtons="auto"
        >
          {profiles
            .filter((p) => p.category === FAMILY_PRACTICE_PROVIDER)
            .map(({ name, img, className }, i) => (
              <Tab
                key={name}
                label={
                  i !== 0 ? (
                    <div style={{ marginTop: "2.5rem" }}>{img}</div>
                  ) : (
                    <>
                      <p style={{ fontSize: "0.75em" }}>
                        Family Practice Providers
                      </p>
                      {img}
                    </>
                  )
                }
                className={className}
                {...a11yProps(i)}
              />
            ))}
          {profiles
            .filter((p) => p.category === NURSE_PRACTITIONER)
            .map(({ name, img, className }, i) => (
              <Tab
                key={name}
                label={
                  i !== 0 ? (
                    <div style={{ marginTop: "2.5rem" }}>{img}</div>
                  ) : (
                    <>
                      <p style={{ fontSize: "0.75em" }}>Nurse Practitioners</p>
                      {img}
                    </>
                  )
                }
                className={`${className}`}
                {...a11yProps(i)}
              />
            ))}
          {profiles
            .filter((p) => p.category === BEHAVIORAL_HEALTH)
            .map(({ name, img, className }, i) => (
              <Tab
                key={name}
                label={
                  i !== 0 ? (
                    <div style={{ marginTop: "2.5rem" }}>{img}</div>
                  ) : (
                    <>
                      <p style={{ color: "black", fontSize: "0.75em" }}>
                        Behavioral Health
                      </p>
                      {img}
                    </>
                  )
                }
                className={`${className}`}
                {...a11yProps(i)}
              />
            ))}
        </Tabs>
      </AppBar>
      {profiles.map((p, i) => (
        <TabPanel
          key={i}
          value={value}
          index={i}
          className={"profile-tab-panel"}
        >
          <Profile profile={p} />
        </TabPanel>
      ))}
    </Paper>
  );
}
