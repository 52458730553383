import { Button, Chip, Divider, Grid, Slide } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { useEffect, useState } from "react";
import NavHashLink from "../../custom-hash-link/hash-link";
import "../../../../../css/square-img-links/link-content.scss";

export default function LinkContent({ title, content, items, link, extLink }) {
  const [slideBarIn, setSlideBarIn] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    isSubscribed &&
      setTimeout(() => {
        setSlideBarIn(true);
      }, 400);

    return () => (isSubscribed = false);
  }, []);

  return (
    <div className={"link-content-root"}>
      <div>
        <h4>
          <b>{title.toUpperCase()}</b>
        </h4>
        <Slide in={slideBarIn} direction={"right"}>
          <Divider style={{ height: 5, width: "100%" }} />
        </Slide>
        {content}
      </div>
      {items && (
        <Grid container className={"chip-container"}>
          {Array.isArray(items) &&
            items.map((item, i) => (
              <Grid item key={i}>
                <Chip
                  label={item}
                  className={"content-chip"}
                  color={"secondary"}
                />
              </Grid>
            ))}
        </Grid>
      )}

      {link && (
        <Button
          variant={"text"}
          color={"secondary"}
          component={NavHashLink}
          to={link}
        >
          LEARN MORE
        </Button>
      )}
      {extLink && (
        <Button
          variant={"text"}
          color={"secondary"}
          component={"a"}
          href={extLink}
          endIcon={<Launch />}
          target={"__blank"}
        >
          LEARN MORE
        </Button>
      )}
    </div>
  );
}
