import { ButtonBase, Container, Grid, Icon } from "@material-ui/core";
import { ChildFriendly, PregnantWoman } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../css/mother-story/story-selector.scss";

import sampleData from "../../SAMPLE-DATA";

export default function StorySelector(props) {
  const dispatch = useDispatch();
  const [stories, setStories] = useState([]);
  const selectedStory = useSelector((state) => state.currentBirthStory);
  const sampleStories = true;

  useEffect(() => {
    const hitAPI = () => {
      return [];
    };

    let data = sampleStories ? sampleData : hitAPI();
    data && setStories(data);
  }, [sampleStories]);

  useEffect(() => {
    stories[0] &&
      dispatch({ type: "SET_CURRENT_BIRTH_STORY", payload: stories[0] });
  }, [stories, dispatch]);

  const StoryLink = ({ imgLink, mother, baby, story, ...rest }) => {
    const [isSelected, setIsSelected] = useState(false);

    const setBirthStory = () => {
      let BirthStory = {
        mother: mother,
        baby: baby,
        imgLink: imgLink,
        story: story,
        ...rest,
      };
      dispatch({ type: "SET_CURRENT_BIRTH_STORY", payload: BirthStory });
    };

    useEffect(() => {
      (selectedStory.mother === mother && selectedStory.baby === baby)&&setIsSelected(true)
    }, [mother, baby]);

    return (
      <Grid item className={`story-link-root ${isSelected&&"selected-story"}`}>
        <ButtonBase
          {...rest}
          style={{ margin: 5 }}
          onClick={setBirthStory}
        >
          <img src={imgLink} alt={`${mother || ""} & ${baby || ""}`} />
          <div className={"name-container"}>
            <h4>
              <Icon>
                <PregnantWoman />
              </Icon>
              {mother || "Mother"}
            </h4>
            <Icon>
              <ChildFriendly />
            </Icon>
            {baby || "Baby"}
          </div>
        </ButtonBase>
      </Grid>
    );
  };

  return (
    <Container maxWidth={"lg"}>
      <h1>Our patients love to share their stories</h1>
      <Grid container justify={"center"}>
        {Array.isArray(stories) &&
          stories.map((s, i) => <StoryLink {...s} key={i} />)}
      </Grid>
      <p style={{ marginTop: 0 }}>*select a story</p>
    </Container>
  );
}
