import { makeStyles } from "@material-ui/core";

export default function WaveTitle({ children, title, icon, mask, bgColor }) {
  const masks = {
    "smooth-arch": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 150"
        filter="drop-shadow(0px 5px 1px rgba(0, 0, 0, .65))"
        style={{
          marginBottom: -10,
          marginTop: -10,
          position: "relative",
          zIndex: 2,
        }}
      >
        <path
          fill={bgColor ? bgColor : "#002b5c"}
          fillOpacity="1"
          d="M0,160L120,138.7C240,117,480,75,720,64C960,53,1200,75,1320,85.3L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
        ></path>
      </svg>
    ),
    "gentle-wave": (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 200"
        filter="drop-shadow(0px 5px 1px rgba(0, 0, 0, .7))"
        style={{
          marginBottom: -10,
          marginTop: -10,
          position: "relative",
          zIndex: 2,
        }}
      >
        <path
          fill={bgColor ? bgColor : "#002b5c"}
          fillOpacity="1"
          d="M0,96L80,96C160,96,320,96,480,117.3C640,139,800,181,960,186.7C1120,192,1280,160,1360,144L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </svg>
    ),
  };

  const useStyles = makeStyles(() => ({
    title: {
      background: bgColor ? bgColor : "#002b5c",
      borderTopRightRadius: 5,
      position: "relative",
      zIndex: 2,
      "& h2": {
        padding: 15,
        margin: 10,
        marginBottom: -5,
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderTopRightRadius: 5,
        "& svg": {
          height: 50,
          width: 50,
        },
      },
    },
  }));
  const classes = useStyles();

  return (
    <div>
      {children}
      <div className={classes.title}>
        <h2>
          {title}
          {icon}
        </h2>
      </div>
      {masks[mask]}
    </div>
  );
}
