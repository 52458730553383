import {
  Button,
  ButtonGroup,
  Grid,
  List,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { ContactMail, FeaturedPlayList } from "@material-ui/icons";

import { appointments } from "../../../routes/paths";
import { TreatmentListBtn } from "../../buttons/buttons";
import HashLink from "../custom-hash-link/hash-link";

import lenna from "../../../../assets/imgs/portraits/lenna.jpg";

export default function WalkinsWelcome({ noButtons }) {
  return (
    <div style={{ maxWidth: 600, margin: "0 auto", padding: 10 }}>
      <h2 className={"primary-title overlined"}>Walk-ins Welcome!</h2>
      <Grid
        container
        wrap={"wrap-reverse"}
        justify={"center"}
        spacing={2}
        alignItems={"center"}
      >
        <Grid item md={6}>
          <h3>
            Patients who need this service will simply need to appear during the
            posted hours and have the following with them:
          </h3>
          <List className={"primary-list"}>
            <ListItemText>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Current Photo Id <ContactMail style={{ marginLeft: 5 }} />
              </span>
            </ListItemText>
            <ListItemText>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Insurance Card <FeaturedPlayList style={{ marginLeft: 5 }} />
              </span>
            </ListItemText>
          </List>
        </Grid>
        <Grid item md={6}>
          {" "}
          <img
            src={lenna}
            alt={"Lenna Opening Door"}
            style={{ maxHeight: 220 }}
          />
        </Grid>
      </Grid>
      {!noButtons && (
        <ButtonGroup>
          <TreatmentListBtn color={"primary"} />
          <Button
            variant={"outlined"}
            component={HashLink}
            color={"primary"}
            to={appointments}
          >
            Learn More
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
}
