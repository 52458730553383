import { List, ListItem, ListItemText, Paper } from "@material-ui/core";
import "../../../../css/visit-the-er/visit-the-er.scss";

export default function VisitTheER() {
  return (
    <Paper className={"visit-the-er-root"}>
      <h2 className={"grad-text-dark overlined"}>
        WHEN TO VISIT THE EMERGENCY ROOM? 
      </h2>
      <p>
        There are a number of concerns or issues that may go past the scope of
        the usual visit to Family Medical Center.{" "}
      </p>
      <p>This can include:</p>
      <List className={"primary-list"}>
        <ListItem>
          <ListItemText>Trouble speaking</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>A loss of consciousness</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Severe cases of asthma</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Symptoms of a heart attack</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>A severe allergic reaction</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Weakness or numbness on one side of the body
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Possible overdose</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Poisoning</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Bleeding that is not able to be controlled
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Severe injuries</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Seizures</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Rape</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Domestic abuse</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            *Any problems that require police help or the evaluation by a
            psychiatrist
          </ListItemText>
        </ListItem>
      </List>

    </Paper>
  );
}
