import { Fragment } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { MenuRounded } from "@material-ui/icons";

import Logo from "../../shared-components/logo/logo";
import { FMCContactButton } from "../../buttons/buttons";
import MenuBarTop from "./menu-bar-top/menu-bar-top";
import Sidebar from "./sidebar/sidebar";

import "../../../../css/menu/menu.scss";
import { toggleMenu } from "../../../redux/actions/actions";

const useStyles = makeStyles(() => ({
  appBar: {
    background: "white",
    minHeight: 94,
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  imgContainer: {
    "& img": {
      margin: 10,
      maxHeight: 70,
    },
  },
  family: {
    "@media(max-width: 799px)": {
      maxWidth: 150,
    },
  },
  menuContact: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px auto",
    marginBottom: 20,
  },
  menuContactTop: {
    display: "flex",
    alignItems: "center",
  },
  mapIcon: {
    "@media(max-width: 342px)": {
      display: "none",
    },
  },
  logoBottom: {
    width: "100%",
  },
  logoContainerBottom: {
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      margin: 5,
    },
  },
  translate: {
    "@media(max-width: 989px)": {
      display: "none",
    },
  },
}));

export default function MainMenu() {
  const menuState = useSelector((state) => state.menu.open);
  const classes = useStyles();
  const dispatch = useDispatch();

  const setMenu = () => {
    dispatch({ type: toggleMenu, payload: !menuState });
  };

  return (
    <div className={"main-menu-root"}>
      <Sidebar />
      <AppBar className={"app-bar"}>
        <Fragment>
          <MenuBarTop />
        </Fragment>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.logoContainer}>
            <div className={`${classes.imgContainer} ${classes.family}`}>
              <Logo />
            </div>
          </div>
          <div className={classes.menuContactTop}>
            <Hidden xsDown>
                <FMCContactButton />
            </Hidden>
            <IconButton onClick={setMenu} style={{marginLeft: 20}}>
              <MenuRounded color={"primary"} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
