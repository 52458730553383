import { Grid, Icon, Paper } from "@material-ui/core";
import { FormatQuote } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../css/mother-story/story.scss";
import { CloseButton } from "../../../buttons/buttons";
import "react-awesome-lightbox/build/style.css";
import StoryGallery from "./story-gallery/story-gallery";

export default function Story() {
  const story = useSelector((state) => state.currentBirthStory);
  const dispatch = useDispatch();

  const backButton = () => {
    dispatch({ type: "SET_CURRENT_BIRTH_STORY", payload: {} });
  };

  return (
    <div className={`story-root`}>
      <Grid
        container
        className={"content"}
        alignItems={"flex-start"}
        spacing={2}
      >
        <Grid item md={4}>
          <Paper className={`profile ${story.gender && story.gender}`}>
            <img src={story.imgLink} alt={`${story.mother} & ${story.baby}`} />

            <div className={"profile-names"}>
              <div className={"baby"}>
                <h3 className={"profile-label"}>Baby:</h3>
                <h4>{story.baby}</h4>
              </div>
              <div className={"parents"}>
                <h3 className={"profile-label"}>Born To:</h3>
                <h4>{story.mother}</h4>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item md={8}>
          <Paper className={"content-paper"}>
            <CloseButton onClose={backButton} title={"Close Story"} />

            <div>
              <h1>Welcome, {story.baby}!</h1>
              {story.quote && (
                <div className={"quote"}>
                  <Icon>
                    <FormatQuote />
                  </Icon>
                  <h3>{story.quote}</h3>
                </div>
              )}
              {story.story}
            </div>
            <StoryGallery images={story.gallery} mother={story.mother} />
            <CloseButton onClose={backButton} title={"Close Story"} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
