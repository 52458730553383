import { Button, ButtonGroup, Grid, Paper } from "@material-ui/core";

import HashLink from "../../../components/shared-components/custom-hash-link/hash-link";
import QuickCare from "../../../components/shared-components/quick-care/quick-care";
import { AfterHoursNotice } from "../../../components/shared-components/banners/notice-banner";
import Logo from "../../../components/shared-components/logo/logo";
import WalkinsWelcome from "../../../components/shared-components/walkins-welcome/walkins-welcome";
import MeetYourProviders from "../../../components/shared-components/meet-your-providers/meet-your-providers";
import SquareImgLinks from "../../../components/shared-components/square-img-links/square-img-links";
import { about, appointments, meetProviders } from "../../../routes/paths";
import { PractitionerBtn } from "../../../components/buttons/buttons";

export const homeInfo = [
  {
    content: (
      <Grid item md={12} className={"intro"}>
        <Paper style={{ padding: 10 }}>
          <div className={"welcome"}>
            <Logo withInfo />
          </div>
          <h4>
            When you are looking for a physician, we are here to provide you
            with affordable personalized care in a friendly setting. We take the
            time to get to know you so we can better understand your healthcare
            needs.{" "}
          </h4>
          <h4>
            At Family Medical Center you will find that our{" "}
            <HashLink to={meetProviders}>staff</HashLink> is committed to making
            sure you have access to the care you need, when you need it. We
            welcome walk-ins and same day{" "}
            <HashLink to={appointments}>appointments</HashLink>.
          </h4>
          <br />
          <ButtonGroup>
            <PractitionerBtn color={"primary"} variant={"contained"} />
            <Button
              component={HashLink}
              variant={"contained"}
              color={"secondary"}
              to={about + "#"}
            >
              About Us
            </Button>
          </ButtonGroup>
        </Paper>
      </Grid>
    ),
  },
  {
    content: (
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        //   wrap={"wrap-reverse"}
      >
        <Grid item md={12} lg={8}>
          <SquareImgLinks title={"Services"} />
        </Grid>
        <Grid item md={12} lg={4}>
          <Paper style={{ maxWidth: 600, margin: "0 auto", padding: 10 }}>
            <WalkinsWelcome />
          </Paper>
        </Grid>
      </Grid>
    ),
  },
  {
    content: (
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        //   wrap={"wrap-reverse"}
      >
        <Grid item sm={12} md={6} lg={4} className={"quickcare-component"}>
          <QuickCare column />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <MeetYourProviders />
        </Grid>
        <AfterHoursNotice />
      </Grid>
    ),
  },
];
