import img1 from "./imgs/alex-hockett-GiP2H_SKh7E-unsplash.jpg";
import img2 from "./imgs/carlo-navarro-WCbCRXk7nmU-unsplash.jpg";
import img3 from "./imgs/christian-bowen-I0ItPtIsVEE-unsplash.jpg";
import img4 from "./imgs/javier-de-la-maza-mOC47QgbHo4-unsplash.jpg";

const stories = [
  {
    mother: "Jane & Larry Doe",
    baby: "Jillian Doe",
    gender: "female",
    imgLink: img1,
    id: "jane-doe-story",
    quote:
      "Pariatur velit cupidatat anim ullamco culpa irure aliquip. Ad pariatur sint commodo ut velit.",
    story:
      "Velit amet sint commodo occaecat aliqua in id ea sit sit dolore aliquip. Voluptate ut aliqua reprehenderit cillum velit minim velit ipsum exercitation eiusmod esse. Occaecat sint reprehenderit id tempor sit laborum voluptate non et nostrud. Labore id sint sint proident nostrud sunt elit sunt reprehenderit Lorem culpa pariatur irure cillum. Reprehenderit non voluptate aliquip pariatur. Aute voluptate sunt consectetur ea eu pariatur laborum consequat exercitation elit reprehenderit laboris. Excepteur commodo in commodo labore sit ad amet elit.",
    gallery: [img1, img2, img3, img4],
  },
  {
    mother: "Samantha Hendricks",
    baby: "Jimmy Hendrix",
    gender: "male",
    imgLink: img2,
    story:
      "Velit amet sint commodo occaecat aliqua in id ea sit sit dolore aliquip. Voluptate ut aliqua reprehenderit cillum velit minim velit ipsum exercitation eiusmod esse. Occaecat sint reprehenderit id tempor sit laborum voluptate non et nostrud. Labore id sint sint proident nostrud sunt elit sunt reprehenderit Lorem culpa pariatur irure cillum. Reprehenderit non voluptate aliquip pariatur. Aute voluptate sunt consectetur ea eu pariatur laborum consequat exercitation elit reprehenderit laboris. Excepteur commodo in commodo labore sit ad amet elit.",
    gallery: [img1, img2, img3, img4],
  },
  {
    mother: "Carol & Dan Dylon",
    baby: "Bobbi Dylon",
    gender: "female",
    imgLink: img3,
    quote:
      "Pariatur velit cupidatat anim ullamco culpa irure aliquip. Ad pariatur sint commodo ut velit.",
    story:
      "Velit amet sint commodo occaecat aliqua in id ea sit sit dolore aliquip. Voluptate ut aliqua reprehenderit cillum velit minim velit ipsum exercitation eiusmod esse. Occaecat sint reprehenderit id tempor sit laborum voluptate non et nostrud. Labore id sint sint proident nostrud sunt elit sunt reprehenderit Lorem culpa pariatur irure cillum. Reprehenderit non voluptate aliquip pariatur. Aute voluptate sunt consectetur ea eu pariatur laborum consequat exercitation elit reprehenderit laboris. Excepteur commodo in commodo labore sit ad amet elit.",
    gallery: [img1, img2, img3, img4],
  },
  {
    mother: "Sharon Yates",
    baby: "John Doe",
    gender: "male",
    imgLink: img4,
    story:
      "Velit amet sint commodo occaecat aliqua in id ea sit sit dolore aliquip. Voluptate ut aliqua reprehenderit cillum velit minim velit ipsum exercitation eiusmod esse. Occaecat sint reprehenderit id tempor sit laborum voluptate non et nostrud. Labore id sint sint proident nostrud sunt elit sunt reprehenderit Lorem culpa pariatur irure cillum. Reprehenderit non voluptate aliquip pariatur. Aute voluptate sunt consectetur ea eu pariatur laborum consequat exercitation elit reprehenderit laboris. Excepteur commodo in commodo labore sit ad amet elit.",
    gallery: [img1, img2, img3, img4],
  },
];

export default stories;
