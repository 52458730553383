import familyMedical from "../assets/svg/family-medical.svg";
import doctors from "../assets/svg/doctors.svg";
import fatherhood from "../assets/svg/fatherhood.svg";
import baby from "../assets/svg/baby.svg";
import questionnaire from "../assets/svg/questionaire.svg";
import doctors2 from "../assets/svg/doctors2.svg";
import { FMCContactButton, TreatmentListBtn } from "../../buttons/buttons";

const Practition = ({ plural }) => <b>Family Practitioner{plural && "s"}</b>;
const FMC = () => <b>Family Medical Center</b>;

const Intro = () => (
  <p>
    Family Medical Center has been offering their services in Weiser for many
    years and has helped maintain a standard of health throughout the community.
    Our <Practition plural /> chose our community so they could have the time to
    get to know their patients, understanding their individual needs and the
    best treatments for their health.
  </p>
);

const FamilyPractice1 = () => (
  <p>
    <Practition plural /> are doctors that have chosen to care for your entire
    family as apposed to specializing in one area or another. They are ready to
    provide comprehensive care to patients of all ages and through all{" "}
    <b>stages</b> of life.
  </p>
);

const MaternityCare = () => (
  <p>
    When you deliver a baby at <FMC /> you are beginning a relationship with our{" "}
    <Practition plural />. Our facility and providers are equipped to ensure
    that you have a pleasant and meaningful birthing experience as well as
    manage all pre and post pregnancy needs.
  </p>
);

const MileStones = () => (
    <p>
      That means that the same <Practition /> that delivers your baby will be
      there for all of his/her milestones and will be building a comprehensive{" "}
      <b>medical history</b> along the way.
    </p>
  );

const KeepingTrack = () => (
    <p>
      By forming a medical history with <FMC /> and taking advantage of our
      Annual Wellness Visit (AWV) you are ensuring that your family has a
      complete image of your health both past and present.
    </p>
  );

const WhenYouNeedUs = () => (
    <div>
      <p>
        Whether you've seen us before or not, <FMC /> is is ready to help. With
        walk-ins available for acute visits and expected low wait times, there
        is no better time than now!
      </p>
      <TreatmentListBtn />
      <FMCContactButton />
    </div>
  );

export const steps = [
  {
    title: "What Are Family Practitioners?",
    content: <FamilyPractice1 />,
    img: doctors,
  },
  {
    title: "Starting With Maternity Care",
    content: <MaternityCare />,
    img: baby,
  },
  {
    title: "Through All The Milestones",
    content: <MileStones />,
    img: fatherhood,
  },
  {
    title: "A Healthy Chart",
    content: <KeepingTrack />,
    img: questionnaire,
  },
  {
    title: "Family Medical Center in Weiser",
    content: <Intro />,
    img: familyMedical,
  },
  {
    title: "Here When You Need Us",
    content: <WhenYouNeedUs />,
    img: doctors2,
  },
];
