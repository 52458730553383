import COVIDBanner from "../../components/shared-components/banners/covid-banner";
import HomeHeader from "../../components/headers/home-header/header";
import "../../../css/pages/home.scss";
import "../../../App.scss";
import BasePage from "../base-page/base-page";
import { homeInfo } from "./data/home-info";
import ContentComponents from "../base-page/content-component";
import { Fragment } from "react";

export default function Home() {
  const helmetData = require("../meta-data.json").home;

  return (
    <Fragment>
      <COVIDBanner />
      <HomeHeader />
      <BasePage helmet={helmetData} noTitle>
        <ContentComponents content={homeInfo} noButtonBar />
      </BasePage>
    </Fragment>
  );
}
