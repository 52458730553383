import { useState } from "react";
import Lightbox from "react-awesome-lightbox";

export default function StoryGallery({ images, mother }) {
  //   const [lightbox, setLightbox] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(null);

  return (
    <div className={"gallery"}>
      {Array.isArray(images) &&
        images.map((s, i) => (
          <img
            src={s}
            alt={`${mother} gallery ${i}`}
            key={i}
            onClick={() => (
              setLightboxIndex(i)
            )}
          />
        ))}
      {(lightboxIndex !== null) && (
        <Lightbox
          images={images}
          startIndex={lightboxIndex}
          onClose={() => setLightboxIndex(null)}
        />
      )}
    </div>
  );
}
