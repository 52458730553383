import React from "react";
import { makeStyles } from "@material-ui/core";
import { AllProviders, PCProviders } from "../doctor-images/doctor-images";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    padding: 10,
  },
}));

export default function FMCImage({ pcProvidersImg = false, ...rest }) {
  const classes = useStyles();

  return (
    <div className={classes.root} {...rest}>
      <PCProviders />
    </div>
  );
}
