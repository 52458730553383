import { Fragment } from "react";
import { Button, Container, Grid } from "@material-ui/core";

import FMCImage from "../../shared-components/FMC-img-card/FMC-img";
import { FMCContactButton } from "../../buttons/buttons";
import { setModal } from "../../popups/popup";

import "../../../../css/headers/home-header.scss";

export default function Header() {
  const HeaderIntro = () => (
    <div className={"header-intro"}>
      <h1>Now Scheduling!</h1>
      <FMCContactButton />
      <Fragment>
        <h4 style={{ marginBottom: 0 }}>8 A.M.–5 P.M. MONDAY - FRIDAY</h4>
        <h4 style={{ margin: 0 }}>OPEN THURSDAY UNTIL 7 P.M.</h4>
        <p style={{ margin: 0 }}>
          <Button
            onClick={() => setModal("treatment", true)}
            color={"primary"}
            variant="outlined"
            style={{ marginTop: 10 }}
          >
            <i>Walk-ins available.</i>
          </Button>
        </p>
      </Fragment>
    </div>
  );

  return (
    <Container className={"main-header-root"} maxWidth={"xl"} disableGutters>
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item md={6} className={"doctor-images"}>
          {/* <FMCImage /> */}
        </Grid>
        <Grid item md={6}>
          <HeaderIntro />
        </Grid>
      </Grid>
    </Container>
  );
}
