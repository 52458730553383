import { Grid } from "@material-ui/core";

import {FMCContactButton} from "../../components/buttons/buttons";

import "../../../css/services/components.scss";

function ContentComponent({ noTitle, title, content, noButtonBar }) {
  return (
    <div
      className={"content-comp-root"}
      id={title && title.replace(/ /g, "-").toLowerCase()}
    >
      {!noTitle && title && <h2>{title.toUpperCase()}</h2>}
      <Grid container spacing={2} justify={"center"} alignItems={"center"}>
        {content}
        {!noButtonBar && (
          <Grid item md={12} className={"button-bar"}>
            <FMCContactButton title={"SCHEDULE NOW"} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default function ContentComponents({ content, ...rest }) {
  return (
    Array.isArray(content) &&
    content.map((s, i) => <ContentComponent {...s} {...rest} key={i} />)
  );
}
