import { useState } from "react";
import { MenuItem, makeStyles, Button, Grow } from "@material-ui/core";
import { OpenInNew, PaymentRounded, VpnKey } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import Translate from "../menu-bar-top/translate/translate";
import HashLink from "../../../shared-components/custom-hash-link/hash-link";
import { toggleMenu } from "../../../../redux/actions/actions";
import { links } from "../data/menu-items-data";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1.5rem",
    "& li": {
      marginBottom: 0,
    },
    "&.dark": {
      color: "black",
      display: "flex",
      justifyContent: "center",
    },
  },
  bottomButtons: {
    display: "flex",
    justifyContent: "space-evenly",
    flexFlow: "column",
    margin: 20,
  },
  link: {
    textDecoration: "none",
    color: "initial",
    display: "flex",
    alignItems: "center",
  },
  noLarge: {
    display: "flex",
    justifyContent: "center",
    "@media(min-width: 650px)": {
      display: "none",
    },
  },
  menuLinkItem: {
    display: "flex",
    flexFlow: "column",
    "& > div": {
      background: "aliceblue",
    },
  },
}));

export default function MenuItems({ dark, noButtons }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.menu.open);

  const closeMenu = () => {
    dispatch({ type: toggleMenu, value: menuState ? false : true });
  };

  const MenuButtons = () => (
    <div className={classes.bottomButtons}>
      <Translate />
      <a
        href={
          "https://zb.rpropayments.com/Login/CheckOutFormLogin/ZXtUJ5Nc-cu9Qzg-Uv2Uf2Wm-G4-"
        }
        target={"__blank"}
        style={{
          textDecoration: "none",
          color: "initial",
          margin: 5,
        }}
      >
        <Button
          onClick={closeMenu}
          endIcon={<PaymentRounded />}
          variant={"contained"}
          color={"primary"}
        >
          Pay A Bill
        </Button>
      </a>
      <a
        href={"https://mycarecorner.net/Default.aspx"}
        target={"__blank"}
        style={{
          textDecoration: "none",
          color: "initial",
          margin: 5,
        }}
      >
        <Button
          onClick={closeMenu}
          variant={"contained"}
          color={"secondary"}
          endIcon={<OpenInNew />}
        >
          Patient Portal
        </Button>
      </a>
      <a
        href={
          "https://passport.benefithub.com/?hsCtaTracking=42ac31b1-f946-457a-b6ce-279ccc044285%7C5968cb01-ae17-4453-8a56-7190a319ebfd"
        }
        style={{
          textDecoration: "none",
          color: "initial",
          margin: 5,
        }}
      >
        <Button
          onClick={closeMenu}
          variant={"contained"}
          color={"secondary"}
          endIcon={<VpnKey />}
        >
          Employee Login
        </Button>
      </a>
    </div>
  );

  const MenuItemLink = ({ title, link, subs }) => {
    const [showSubs, setShowSubs] = useState(false);

    return (
      <MenuItem
        onClick={link ? closeMenu : () => setShowSubs(!showSubs)}
        component={link ? HashLink : null}
        to={link}
        className={classes.menuLinkItem}
      >
        {title}
        {subs && (
          <Grow in={showSubs} unmountOnExit>
            <div>
              {subs.map((s, i) => (
                <MenuItemLink key={i} {...s} />
              ))}
            </div>
          </Grow>
        )}
      </MenuItem>
    );
  };

  return (
    <div className={`${classes.root} ${dark && "dark"}`}>
      {links.map((l, i) => (
        <MenuItemLink key={i} {...l} />
      ))}
      {!noButtons && <MenuButtons />}
    </div>
  );
}
