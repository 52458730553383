import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Grow,
  Icon,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { ExpandMoreRounded, Facebook, Warning } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '1rem',
    "& ul ul": {
      color: "aliceblue",
      borderRadius: 10,
      padding: 20,
      paddingLeft: 30,
      marginBottom: 15,
      background: theme.palette.primary.main,
      border: {
        top: "10px solid #c40c15",
        bottom: "5px solid #c40c15",
      },
    },
  },
  rightSide: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
  },
  alternate: {
    marginTop: "4.5rem",
    borderRight: `5px solid ${theme.palette.primary.main}`,
    "@media(max-width: 960px)": {
      background: theme.palette.primary.main,
      color: "aliceblue",
      "& a": {
        color: "white",
        textDecoration: "underline",
      },
      "& svg": {
        color: "white",
      },
    },
  },
}));

export default function COVIDComponent(props) {
  const QandA = require("./q&a.json");
  const [fullQandA, setFullQandA] = useState(false);
  const classes = useStyles();

  const openQandA = () => {
    setFullQandA(!fullQandA);
  };

  return (
    <div className={"COVID-COMP-root"} id={"COVID"}>
      <div style={{ textAlign: "center" }}>
        <Icon>
          <Warning style={{ height: 100, width: 100, color: "#c40c15" }} />
        </Icon>
        <h1 className={"primary-title"}>COVID TESTING & VACCINE UPDATES</h1>
        <h3>The questions you've been asking</h3>
      </div>
      <div className={classes.root}>
        <Grid container spacing={3} style={{ textAlign: "center" }}>
          {Array.isArray(QandA) &&
            QandA.map(
              (q, i) =>
                q.update && (
                  <Grid item md={12} key={i}>
                    <div dangerouslySetInnerHTML={{ __html: q.update }} />
                  </Grid>
                )
            )}

          {Array.isArray(QandA) &&
            QandA.map((q, i) =>
              i <= 2
                ? q.question && (
                    <Grid key={i} item xs={12} md={6}>
                      <Accordion
                        defaultExpanded={
                          window.innerWidth <= 767 ? false : true
                        }
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreRounded />}
                          aria-controls={`${q.question}-open`}
                          id={`${q.question}-id`}
                        >
                          {q.question.toUpperCase()}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div dangerouslySetInnerHTML={{ __html: q.answer }} />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )
                : null
            )}
          <Grow in={fullQandA} unmountOnExit>
            <>
              {Array.isArray(QandA) &&
                QandA.map(
                  (q, i) =>
                    i > 2 &&
                    q.question && (
                      <Grid key={i} item xs={12} md={6}>
                        <Accordion
                          className={
                            i % 2 === 0 ? classes.alternate : classes.rightSide
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreRounded />}
                            aria-controls={`${q.question}-open`}
                            id={`${q.question}-id`}
                          >
                            {q.question.toUpperCase()}
                          </AccordionSummary>
                          <AccordionDetails>
                            <div
                              dangerouslySetInnerHTML={{ __html: q.question }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )
                )}
            </>
          </Grow>
          <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={openQandA}
              style={{ padding: "1rem", margin: "10px auto" }}
            >
              Full COVID Q&A HERE
            </Button>
          </Grid>
          {Array.isArray(QandA) &&
            QandA.map(
              (q, i) =>
                q.content && (
                  <div
                    dangerouslySetInnerHTML={{ __html: q.content }}
                    key={i}
                  />
                )
            )}
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a href={"tel:+12085490370"}>(208) 549-0370</a>
            <a
              href={"https://www.facebook.com/WeiserMemorialHospital/"}
              target={"__blank"}
              rel={"__noopen nofollow"}
            >
              <IconButton>
                <Facebook />
              </IconButton>
            </a>
            <a href={"https://coronavirus.idaho.gov/"}>
              https://coronavirus.idaho.gov/
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
