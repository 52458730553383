import blanchard from "../../../../assets/imgs/portraits/drblanchard.jpg";
import wheeler from "../../../../assets/imgs/portraits/anndi_wheeler.jpg";
import terry from "../../../../assets/imgs/portraits/brad_terry.jpg";
import hathhorn from "../../../../assets/imgs/portraits/melissa-hathhorn.jpg";
import smith from "../../../../assets/imgs/portraits/melissa-smith.jpg";
import ryan from "../../../../assets/imgs/portraits/ryan-ankarberg.jpg";
import gallegos from "../../../../assets/imgs/portraits/Stephanie_Gallegos.jpg";
import allProvidersImg from "../../../../assets/imgs/portraits/familymedical-group.jpg";
import pcProviders from "../../../../assets/imgs/portraits/primary_care.jpg";
import ankarberg from "../../../../assets/imgs/portraits/ankarberg.jpg";
import martin from "../../../../assets/imgs/portraits/martin.jpg";
import ali from "../../../../assets/imgs/portraits/Ali_Capurro.jpg";

export const docList = [
  {
    src: blanchard,
    name: "Jordan Blanchard, M.D.",
  },
  {
    src: terry,
    name: "Brad Terry, D.O.",
  },
  {
    src: ryan,
    name: "Ryan Ankarberg, PA-C",
  },
  {
    src: wheeler,
    name: "Andrea Wheeler N.P.",
  },
  {
    src: smith,
    name: "Melissa Smith, MD",
  },
  {
    src: hathhorn,
    name: "Melissa Hathhorn, PMHNP-BC",
  },
  {
    src: gallegos,
    name: "Stephanie Gallegos, LMSW",
  },
  {
    src: ankarberg,
    name: "Selena Ankarberg, FNP",
  },
  {
    src: martin,
    name: "Sarah Martin, FNP",
  },
  {
    src: allProvidersImg,
    name: "Family Medical Providers",
  },
  {
    src: pcProviders,
    name: "Primary Care Providers",
  },
  {
    src: ali,
    name: "Ali Capurro, LPC",
  },
];
