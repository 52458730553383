import { Switch, Route, Redirect } from "react-router";

import Contact from "../pages/contact/contact";
import Home from "../pages/home/home";
import Information from "../pages/information/information";
import Covid from "../pages/covid/covid";
import AboutPage from "../pages/about/about";
import ServicesPage from "../pages/services/services-page";
import PatientStories from "../components/patient-story/patient-story";
import PageNotFound404 from "../pages/404/pagenotfound404";
import {
  about,
  contact,
  covid,
  familyPractitioners,
  home,
  information,
  patientStories,
  services,
  appointments,
  billing,
  maternity,
} from "./paths";
import FP from "../pages/family-practitioners/fp";

const redirectPaths = [
  ["/index.html", <Redirect to={home} />],
  ["/fmc--our-services.html", <Redirect to={services} />],
  ["/fmc--appointments.html", <Redirect to={appointments} />],
  ["/fmc--our-location.html", <Redirect to={contact} />],
  ["/fmc--billing-procedures---forms2.html", <Redirect to={billing} />],
  ["/fmc--maternity-care.html", <Redirect to={maternity} />],
];

export const paths = [
  [home, <Home />],
  [services, <ServicesPage />],
  [information, <Information />],
  [contact, <Contact />],
  [covid, <Covid />],
  [patientStories, <PatientStories />],
  [about, <AboutPage />],
  [familyPractitioners, <FP />],
];

const completePaths = [...paths, ...redirectPaths, ["*", <PageNotFound404 />]];

export function Routes() {
  return (
    <Switch>
      {completePaths.map((p) => (
        <Route key={p[0]} exact path={p[0]} render={() => p[1]} />
      ))}
      <Route
        exact
        path="/sitemap.xml"
        onEnter={() => window.location.reload()}
      />
    </Switch>
  );
}
