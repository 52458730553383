import { Grid, Icon, Paper } from "@material-ui/core";
import { Warning } from "@material-ui/icons";

export default function NoticeBanner({ content, icon }) {
  return (
    <Paper className={"notice-banner"}>
      <Grid container alignItems={"center"} justify={"center"}>
        <Grid item md={1}>
          <Icon>{icon ? icon : <Warning />}</Icon>
        </Grid>
        <Grid item md={11}>
          {content}
        </Grid>
      </Grid>
    </Paper>
  );
}

export function AfterHoursNotice() {
  return (
    <NoticeBanner
      content={
        <p className={"danger"}>
          *Should you have an urgent problem after Clinic hours, you can reach
          our answering service at{" "}
          <a href={"tel:12085494424"}>(208) 549-4424</a> and they will contact
          our provider on call for you.
        </p>
      }
    />
  );
}
