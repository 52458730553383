import HashLink from "../../shared-components/custom-hash-link/hash-link";
import { Grid } from "@material-ui/core";

import { home } from "../../../routes/paths";
import FMCContactCard from "../contact-components/contact-selector/contact-card/fmc-contact-card";
import Hours from "../contact-components/hours/hours";

import accredit from "../../../../assets/imgs/logos/dnvlogomasterpage1.png";
import workplace from "../../../../assets/imgs/logos/workplace2023.png";
import logo from "../../../../assets/imgs/logos/FMC_logo.svg";

import "../../../../css/logo/logo.scss";

export default function Logo({ withInfo, ...rest }) {
  return (
    <Grid
      container
      className={"logo-container"}
      {...rest}
      justify={"space-around"}
      alignItems={"center"}
      wrap={"wrap"}
    >
      <Grid item>
        <HashLink to={home + "#"}>
          <img src={logo} alt={"FMC Logo"} />
        </HashLink>
      </Grid>
      {withInfo && (
        <Grid item>
          <Grid
            container
            alignItems={"center"}
            justify={"space-evenly"}
            style={{ minWidth: 320 }}
          >
            <Grid item>
              <div className={"info"}>
                <FMCContactCard />
                <Hours hoursOnly />
              </div>
            </Grid>
            <Grid item className={"logo-images"}>
              <img src={workplace} alt={"Best Places To Work 2021"} />
              <img src={accredit} alt={"Accredited Critical Access Hospital"} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
