import { Fragment } from "react";
import { Button } from "@material-ui/core";

import { store } from "../../../../redux/store/index";
import { FMCContactButton } from "../../../buttons/buttons";
import { setModal } from "../../../popups/popup";

export const quickCareData = [
  // {
  //   title: "VIRTUAL VISITS",
  //   children: (
  //     <Fragment>
  //       <p>
  //         Online visits help you skip-the-trip to our office. Start a{" "}
  //         <b>scheduled</b> virtual visit here.
  //       </p>
  //       <Button
  //         variant={"contained"}
  //         color={"primary"}
  //         component={HashLink}
  //         to={appointments}
  //       >
  //         Start Visit
  //       </Button>
  //     </Fragment>
  //   ),
  //   small: true,
  // },
  {
    title: "MAKE AN APPOINTMENT",
    children: (
      <Fragment>
        {/* <h5 style={{ margin: 0 }}>ONLINE SCHEDULING COMING SOON!</h5> */}
        <p>
          Call to schedule an appointment, or come by for an walk-in visit.
        </p>
        <FMCContactButton buttonOnly />
      </Fragment>
    ),
    small: true,
  },
  {
    title: "PATIENT DOCUMENTS",
    children: (
      <Fragment>
        <p>
          Find and download any documents you may need for your appointment or
          other patient needs.
        </p>
        <Button
          variant={"contained"}
          color={"primary"}
          component={Button}
          onClick={() =>
            store.dispatch({ type: "SET_SHOW_DOWNLOADS_LIST", payload: true })
          }
        >
          Documents
        </Button>
      </Fragment>
    ),
    small: true,
  },
];
