import { Container } from "@material-ui/core";
import "../../../css/footer/main-footer.scss";
import Logo from "../shared-components/logo/logo";
import MenuItems from "../menu/main-menu/menu-items/menu-items";

export default function Footer() {
  const date = new Date();

  return (
    <Container maxWidth={"xl"} disableGutters className={"main-footer"}>
        <MenuItems dark noButtons />
        <div className={"logo"}>
          <Logo withInfo />
        </div>
      <p>
        &copy; {date.getFullYear()} Weiser Memorial Hospital
      </p>
      {/* <p>
        Designed & Developed by{" "}
        <a
          href={"https://lemonster.dev"}
          style={{
            textDecoration: "none",
            color: "#88aa00",
            // background: '#232323',
            borderRadius: 2.5,
          }}
        >
          LeMonster.dev
        </a>
      </p> */}
    </Container>
  );
}
