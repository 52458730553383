import { useEffect, useState } from "react";
import { ClickAwayListener, Grid, Slide } from "@material-ui/core";
import HashLink from "../custom-hash-link/hash-link";

import WaveTitle from "../wave-title/wave-title";
import LinkContent from "./link-content/link-content";
import linkData from "./data/links";

import "../../../../css/square-img-links/square-img-links.scss";

export default function SquareImgLinks({
  linkProps,
  smallLinks,
  title,
  rounded,
  ...rest
}) {
  const [links, setLinks] = useState(linkData);
  const [showingContentId, setShowingContentId] = useState(null);

  const SquareImgLink = ({ src, link, title, content, linkId, linkStyles }) => {
    const [showContent, setShowContent] = useState(false);

    const showContentForCard = (id) => {
      setShowingContentId(id);
    };

    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
        linkProps && setLinks(linkProps);
        showingContentId === linkId && setShowContent(true);
      }

      return () => {
        isSubscribed = false;
      };
    }, [linkId]);

    return !smallLinks ? ( // Full link square with sliding content box
      <ClickAwayListener onClickAway={showContentForCard} touchEvent={false}>
        <div
          style={{
            background: `
          linear-gradient(
              to bottom, 
              rgba(255,255,255, 0.15), 
              rgba(0,0,0,0.75)
          ),
          url(${src})`,
            backgroundSize: "cover",
            ...linkStyles,
          }}
          className={`square-img-link-root ${rounded && "rounded"}`}
          // if the content is not being showed it will activate the slide and remove content from current square
          onClick={() => showContentForCard(linkId)}
          id={title && title.replace(" ", "-").toLowerCase()}
        >
          <WaveTitle mask={"smooth-arch"} title={title} {...rest}>
            <div
              className={`link-content ${smallLinks && "small-links"} ${
                showContent && "opened"
              }`}
            >
              <Slide
                in={showContent}
                direction={"down"}
                onClose={() => setShowContent(false)}
                timeout={500}
                mountOnEnter
              >
                <div className={"slide-content"}>
                  <LinkContent
                    title={title}
                    link={link}
                    {...content}
                    onClose={showContentForCard}
                  />
                </div>
              </Slide>
            </div>
          </WaveTitle>
        </div>
      </ClickAwayListener>
    ) : (
      // Small link box linking directly to "link"
      <HashLink to={link}>
        <div
          style={{
            // background image settings
            background: `
      linear-gradient(
          to bottom, 
          rgba(255,255,255, 0.15), 
          rgba(0,0,0,0.75)
      ),
      url(${src})`,
            backgroundSize: "cover",
            ...linkStyles,
          }}
          className={`square-img-link-root ${rounded && "rounded"}`}
          id={title && title.replace(" ", "-").toLowerCase()}
        >
          <WaveTitle mask={"smooth-arch"} title={title} {...rest} />
        </div>
      </HashLink>
    );
  };

  return (
    <div className={`square-img-links-root ${smallLinks && "small"}`}>
      {title && <h2 style={{ color: "white" }}>{title}</h2>}
      <Grid container spacing={2} justify={"center"}>
        {Array.isArray(links) &&
          // map through ./data/links.js or supplied links
          links.map((l, i) => (
            <Grid item key={i} className={"link-grid-item"}>
              <SquareImgLink {...l} linkId={i} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
