import BasePage from "../base-page/base-page";
import ContentComponents from "../base-page/content-component";
import { aboutInfo } from "./data/about-info";

export default function AboutPage() {
  const helmetData = require("../meta-data.json").about;

  return (
    <BasePage noTitle helmet={helmetData}>
      <ContentComponents noButtonBar content={aboutInfo} />
    </BasePage>
  );
}
