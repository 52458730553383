import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { setModal } from "../popups/popup";

import "../../../css/buttons/buttons.scss";

export function CloseButton({ onClose, title, icon, ...rest }) {
  return (
    <Button
      onClick={() => onClose()}
      className={"close-btn"}
      style={{ padding: 0 }}
      startIcon={icon ? icon : <Close />}
      disableElevation
      {...rest}
    >
      {title ? title : "CLOSE"}
    </Button>
  );
}

export function FMCContactButton({ title, buttonOnly }) {
  return !buttonOnly ? (
    <div className={"fmc-contact-button"}>
      <h3>{title ? title : "CALL NOW"}</h3>
      <Button
        variant={"contained"}
        color={"secondary"}
        component={"a"}
        href={"tel:12085494424"}
      >
        (208) 549-4424
      </Button>
    </div>
  ) : (
    <Button
      variant={"contained"}
      color={"secondary"}
      component={"a"}
      href={"tel:12085494424"}
    >
      {title ? title : "(208) 549-4424"}
    </Button>
  );
}

export function TreatmentListBtn(props) {
  return (
    <Button
      variant={"text"}
      onClick={() => setModal("treatment", true)}
      {...props}
    >
      What we treat
    </Button>
  );
}

export const PractitionerBtn = (props) => (
  <Button
    variant={"text"}
    onClick={() => setModal("practitioners", true)}
    {...props}
  >
    What Are Family Practitioners
  </Button>
);
