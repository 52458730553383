import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('1', "$12,760", "$18,240", "$25,520", "$38,280"),
  createData('2', "$17,240", "$25,860", "$34,480", "$51,720"),
  createData('3', "$21,720", "$32,580", "$43,440", "$65,160"),
  createData('4', "$26,200", "$39,300", "$52,400", "$78,600"),
  createData('5', "$30,680", "$46,020", "$61,360", "$92,040"),
  createData('6', "$35,160", "$52,740", "$70,320", "$105,480"),
  createData('7', "$39,640", "$59,460", "$79,280", "$158,560"),
  createData('8', "$44,120", "$66,180", "$88,240", "$176,480"),
];

export default function SlidingScale() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <caption>*Federal Poverty Level</caption>
        <TableHead>
          <TableRow>
            <TableCell>HOUSEHOLD SIZE</TableCell>
            <TableCell align="right">100% *FPL<br/>ANNUAL GROSS.</TableCell>
            <TableCell align="right">150% *FPL<br/>ANNUAL GROSS.</TableCell>
            <TableCell align="right">200% *FPL<br/>ANNUAL GROSS.</TableCell>
            <TableCell align="right">300% *FPL<br/>ANNUAL GROSS.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}