import { RoomService } from "@material-ui/icons";
import BasePage from "../base-page/base-page";
import ContentComponents from "../base-page/content-component";
import { serviceItems } from "./data/service-component-data";

export default function ServicesPage() {
  const helmetData = require("../meta-data.json").services

  return (
    <BasePage title={"Our Services"} icon={<RoomService />} helmet={helmetData}>
      <ContentComponents content={serviceItems} />
    </BasePage>
  );
}
