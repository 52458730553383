export const home = "/";
export const services = "/services";
export const information = "/information";
export const contact = "/contact";
export const about = "/about";
export const covid = "/covid";
export const familyPractitioners = "/family-practitioners";

export const appointments = "/information#appointments";
export const billing = "/information#billing";
export const findUs = "/information#find-us";
export const patientRights = "/information#patient-rights";

export const meetProviders = "/about#meet-your-providers";

export const maternity = "/services#maternity-care";
export const behavioralHealth = "/services#behavioral-health";
export const primaryCare = "/services#primary-care";
export const immunizations = "/services#immunizations";

export const patientStories = "/patient-stories";
