import { makeStyles } from "@material-ui/core";
import { docList } from "./data";

const useStyles = makeStyles(() => ({
  fmcDr: {
    display: "flex",
    color: "white",
    width: "min-content",
    "& p": {
      // align text up side of image
      writingMode: "vertical-lr",
      textOrientation: "mixed",
      width: "max-content",
      marginRight: -25,
      zIndex: 100,
      "@media(max-width: 767px)": {
        maxHeight: 120,
        marginRight: -15,
        marginBottom: -15,
        fontSize: "0.5rem",
      },
      transition: ".52s ease all",
      "&.no-name": {
        color: "transparent",
      },
    },
    "&> img": {
      maxWidth: 350,
      borderRadius: 10,
      maxHeight: 200,
      "@media(max-width: 767px)": {
        maxHeight: 120,
      },
    },
  },
  fmcDrNoSmall: {
    display: "flex",
    color: "white",
    width: "min-content",
    "& p": {
      // align text up side of image
      writingMode: "vertical-lr",
      textOrientation: "mixed",
      width: "max-content",
      marginRight: -25,
      zIndex: 100,
    },
    "&> img": {
      maxWidth: 350,
      borderRadius: 10,
      maxHeight: 200,
    },
  },
  "all-docs": {
    "& p": {
      fontSize: "1.25em",
      borderRadius: 2.5,
      marginRight: -29,
      color: "initial",
      background: "rgba(255,255,255,0.75)",
    },
    "&> img": {
      maxWidth: 420,
      maxHeight: 520,
      "@media(max-width: 767px)": {
        maxWidth: 320,
        maxHeight: 420,
      },
    },
  },
}));

export const DocImg = ({
  src,
  name,
  category,
  noSmall,
  noName,
  allDocs,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div
      className={`${!noSmall ? classes.fmcDr : classes.fmcDrNoSmall} ${
        allDocs ? classes["all-docs"] : ""
      }`}
      {...rest}
    >
      <p className={noName ? "no-name" : ""}>
        {name.split(" ")[0]} {name.split(" ")[1].replace(",", "")}
      </p>
      <img
        style={{ margin: 5, borderRadius: 10, width: "initial" }}
        src={src}
        alt={name}
      />
    </div>
  );
};

export const BlanchardImg = ({ ...rest }) => {
  return <DocImg {...docList[0]} {...rest} />;
};
export const TerryImg = ({ ...rest }) => {
  return <DocImg {...docList[1]} {...rest} />;
};
export const RyanImg = ({ ...rest }) => {
  return <DocImg {...docList[2]} {...rest} />;
};
export const WheelerImg = ({ ...rest }) => {
  return <DocImg {...docList[3]} {...rest} />;
};
export const SmithImg = ({ ...rest }) => {
  return <DocImg {...docList[4]} {...rest} />;
};
export const HathhornImg = ({ ...rest }) => {
  return <DocImg {...docList[5]} {...rest} />;
};
export const GallegosImg = ({ ...rest }) => {
  return <DocImg {...docList[6]} {...rest} />;
};
export const AnkarbergImg = ({ ...rest }) => {
  return <DocImg {...docList[7]} {...rest} />;
};
export const MartinImg = ({ ...rest }) => {
  return <DocImg {...docList[8]} {...rest} />;
};
export const AllProviders = ({ ...rest }) => {
  return <DocImg {...docList[9]} allDocs noSmall {...rest} />;
};
export const PCProviders = ({ ...rest }) => {
  return <DocImg {...docList[10]} {...rest} />;
};
export const AliImg = ({ ...rest }) => {
  return <DocImg {...docList[11]} {...rest} />;
};
